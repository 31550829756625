import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import Product from 'classes/product';
import ProductState from 'classes/productstate';
import User from 'classes/user';
import ajax from 'controllers/ajax';
import analytics from 'controllers/analytics';
import storage from 'controllers/storage';
import upload from 'controllers/upload';
import {
	RouteOptions,
	NavigateOptions,
	ChannelModel,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import openUrl from 'ops/open-url';
import openWindow from 'ops/open-window';
import * as DialogService from 'services/dialog';
import getDelegateUrl from 'services/get-delegate-url';
import { OfferingGroups } from 'settings/offerings';
import {
	CartModule,
	ConfigModule,
	ThemeDataModule,
	CartItemsModule,
	ProductsModule,
	ProductStateModule,
	AppStateModule,
	UserModule,
	AppDataModule,
	ThemeStateModule,
} from 'store/index';
import parseUrl from 'tools/parse-url';
import _ from 'underscore';
import FramePickerView from 'views/frame-picker';
import OfferingOptionsView from 'views/offering-options';
import UploadErrorsView from 'views/upload-errors';

class Navigate {
	history: string[];

	constructor() {
		this.history = [window.location.pathname.replace(
			'/app',
			'',
		)];
	}

	public go(
		route: string,
		routeOptions: RouteOptions,
		opts?: NavigateOptions,
	) {
		const options = opts || {};

		if (options.query) {
			route += `?${options.query}`;
		}
		if (options.hash) {
			route += `#${options.hash}`;
		}

		if ((window.swUpdate || window.dataUpdate) && ProductStateModule.getSaved) {
			// Service worker and/or the app data is updated, reload for new version of app
			console.log('ServiceWorker/AppCache and/or appData update: reload location href');

			let l = `${window.location.protocol}//${window.location.host}/app`;
			if (route.length) {
				l += `/${route}`;
			}
			window.location.href = l;
		} else {
			// If this route replaces the previous, then remove the previous from the history array
			if (routeOptions.replace) {
				this.history.pop();
			}

			// Push route to history array
			this.history.push(route);
			window.App.router.navigate(
				`/${route}`,
				routeOptions,
			);
		}
	}

	public back(
		options?: NavigateOptions,
		fallback?: (o?: NavigateOptions) => void,
	) {
		options = _.extend(
			{
				slide: true,
				slideDirection: 'right',
			},
			options,
		);

		// remove current route
		this.history.pop();

		if (this.history.length === 0 && fallback) {
			fallback(options);
		} else {
			window.App.router.back();
		}
	}

	public backToReferrer() {
		if (this.history.length) {
			return window.history.go(
				-this.history.length,
			);
		}

		return this.back();
	}

	private addUrlParameters(route: string) {
		// Add all active url parameters to the route
		const parsedUrl = parseUrl(window.location.href);
		const urlVars = parsedUrl.parameters;
		route += '?';
		_.each(
			_.pairs(urlVars),
			([prop, value]) => {
				route += `${prop}=${value}&`;
			},
		);

		return route.substring(
			0,
			route.length - 1,
		);
	}

	public openCart() {
		if (!ConfigModule.hasCartModule) {
			// No shopping cart module, go to external webshop

			// Track cart pageview for analytics conversion measurement
			analytics.trackPageView(
				'cart',
				'Shopping cart',
			);

			// Submit cart
			this.toExternalWebshop();
		} else {
			// Go to shoppingcart overview page
			this.toCart();
		}
	}

	private proceedFromCart() {
		// Update all cartitem data on server
		CartItemsModule
			.putModels({
				data: CartItemsModule.collection,
			})
			.then(() => {
				if (!ConfigModule.hasWebshopModule) {
					this.toExternalWebshop();
				} else {
					DialogService.closeLoaderDialog();
					this.toWebshop();
				}
			})
			.catch(DialogService.closeLoaderDialog);
	}

	public proceedFromCreate(
		productModel: DB.ProductModel,
	) {
		if (ThemeStateModule.themeHasPhotoSelect) {
			if (window.nativeToWeb) {
				window.nativeToWeb.pickerDismissed = () => {
					this.back();
				};
			}

			this.toSelect(
				productModel.id,
				{
					showSelection: false,
					removeUnselected: true,
				},
				{
					trigger: true,
					replace: true,
				},
			);
		} else {
			this.openProduct(
				productModel.id,
				{
					replace: true,
				},
			);
		}
	}

	private enhancementCheck(): Promise<void> {
		if (ProductStateModule.getProductSettings.applyEnhancement === undefined) {
			return ProductState.askEnhancementConfirmation();
		}

		return Promise.resolve();
	}

	public goBack(
		source: 'editProduct' | 'end',
	): void {
		const { productId } = ProductStateModule;
		if (!productId) {
			throw new Error('Could not find required productId');
		}

		if (source === 'editProduct') {
			if (ThemeStateModule.themeHasPhotoSelect) {
				if (window.glPlatform === 'native') {
					if (window.nativeToWeb) {
						window.nativeToWeb.pickerDismissed = () => {
							this.toStart();
						};
					}

					return this.toSelect(
						productId,
						{
							showSelection: false,
							removeUnselected: true,
						},
					);
				}

				return this.toLibrary(productId);
			}

			if (!ConfigModule.hasStoreModule) {
				// When the store module is not configured, we need the user to keep the browser history,
				// so that we can navigate back to the referrer. We therefore need to use the back function here.
				return this.backToReferrer();
			}

			return this.toStart();
		}

		if (source === 'end') {
			// Reset scroll position (in case we are going back to the pages overview)
			AppStateModule.setScrollPosition(0);

			return window.App.router.openProduct(
				productId,
				false,
			);
		}

		throw new Error('Unknown source to goBack from');
	}

	public goForward(source: 'fill' | 'selection' | 'shoppingcart' | 'preview' | 'overview' | 'end' | 'editor' | 'crop'): void {
		if (source == 'fill') {
			const productModel = ProductStateModule.getProduct;
			const { productId } = ProductStateModule;
			if (!productModel) {
				throw new Error('Could not find required product model');
			}
			if (!productId) {
				throw new Error('Could not find required productId');
			}

			// Switch to next step
			if (OfferingGroups(
				productModel.group,
				['BookTypes', 'PageSets'],
			)) {
				this.toOverview(productId);
			} else if (OfferingGroups(
				productModel.group,
				['BasicProducts'],
			)) {
				this.toEditor(
					productId,
					'0',
				);
			} else {
				// Open front page in editor
				this.toPreview(productId);
			}
		} else if (source == 'selection') {
			const productModel = ProductStateModule.getProduct;

			if (!productModel) {
				throw new Error('Could not find required product model');
			}

			const themeModel = ThemeDataModule.getTheme(productModel.themeid);
			const photoModels = ProductStateModule.getPhotosSelected;
			const photoCount = photoModels.length;

			const minphotos = !themeModel || _.isNull(themeModel.minphotos)
				? 1
				: themeModel.minphotos;

			upload.waitForFileConversion().finally(() => {
				if (ProductStateModule.getPhotosFailed.length > 0) {
					const closeError = DialogService.openErrorDialog({
						header: {
							hasCloseButton: false,
						},
						body: {
							component: UploadErrorsView,
						},
						footer: {
							buttons: [
								{
									id: 'abort',
									text: window.App.router.$t('dialogButtonUploadErrorsCancel'),
									click: () => {
										const closeLoader = DialogService.openLoaderDialog();

										ProductStateModule
											.retryPhotoErrors()
											.catch(() => {
												// Swallow error: no action required
											})
											.then(() => {
												closeLoader();
												this.goForward(source);
											});
										closeError();
									},
								},
								{
									id: 'accept',
									text: window.App.router.$t('dialogButtonUploadErrorsOk'),
									click: () => {
										const errorModels = ProductStateModule.getPhotosFailed;
										errorModels
											.slice(0)
											.forEach((photoModel) => {
												ProductStateModule.removePhoto(photoModel.id);
											});
										this.goForward(source);
										closeError();
									},
								},
							],
						},
						width: 300,
					});
				} else if (photoCount < minphotos && ThemeStateModule.themeHasPhotoSelect) {
					analytics.trackEvent(
						'Invalid photo count',
						{
							category: 'Error',
							label: 'Product photo count',
							value: photoCount,
						},
					);

					const closeError = DialogService.openErrorDialog({
						header: {
							title: window.App.router.$t('dialogHeaderNotEnoughPhotosSelected'),
						},
						body: {
							content: window.App.router.$t(
								'dialogTextNotEnoughPhotosSelected',
								{
									count: minphotos - photoCount,
								},
							),
						},
						footer: {
							buttons: [
								{
									id: 'accept',
									text: window.App.router.$t('dialogButtonErrorOk'),
									click: () => {
										if (window.glPlatform == 'native') {
											const maxPhotos = (
												themeModel && themeModel.maxphotos
													? themeModel.maxphotos - ProductStateModule.getPhotosSelected.length
													: undefined
											);

											upload.filePicker(
												maxPhotos,
												{
													showSelection: false,
													removeUnselected: true,
												},
											);
										}

										closeError();
									},
								},
							],
						},
					});
				} else {
					const productSettings = ProductStateModule.getProductSettings;

					ProductState
						.addProductProperties()
						.finally(() => {
							if (
								OfferingGroups(
									productModel.group,
									['WallDecoration', 'BookTypes', 'Cards'],
								)
								&& (
									typeof productSettings.autoFill === 'undefined'
									|| productSettings.autoFill
								)
								&& _.findWhere(
									ProductStateModule.getObjects,
									{ editable: 1 },
								)
							) {
								const closeConfirm = DialogService.openConfirmDialog({
									header: {
										hasCloseButton: true,
										title: window.App.router.$t('dialogHeaderReplaceContent'),
									},
									body: {
										content: window.App.router.$t('dialogTextReplaceContent'),
									},
									footer: {
										buttons: [
											{
												id: 'replace',
												text: window.App.router.$t('dialogButtonReplaceContentOk'),
												click: () => {
													const { productId } = ProductStateModule;

													if (!productId) {
														closeConfirm();
														throw new Error('Missing product id');
													}

													ProductState
														.select(productId)
														.then(() => Product.fill({
															replaceContent: true,
															showBuildOptions: false,
														}))
														.catch(() => {
															if (OfferingGroups(
																productModel.group,
																['BookTypes', 'PageSets'],
															)) {
																this.toOverview(productId);
															} else if (OfferingGroups(
																productModel.group,
																['BasicProducts'],
															)) {
																this.toEditor(
																	productId,
																	'0',
																);
															} else {
																// Open front page in editor
																this.toPreview(productId);
															}
														});

													closeConfirm();
												},
											},
											{
												id: 'noreplace',
												text: window.App.router.$t('dialogButtonReplaceContentCancel'),
												click: () => {
													if (ProductStateModule.productId) {
														if (OfferingGroups(
															productModel.group,
															['BookTypes', 'PageSets'],
														)) {
															this.toOverview(ProductStateModule.productId);
														} else if (OfferingGroups(
															productModel.group,
															['BasicProducts'],
														)) {
															this.toEditor(
																ProductStateModule.productId,
																'0',
															);
														} else {
															// Open front page in editor
															this.toPreview(ProductStateModule.productId);
														}
													}

													closeConfirm();
												},
											},
										],
										wrapButtons: true,
									},
								});
							} else {
								Product.fill();
							}
						});
				}
			});
		} else if (source == 'shoppingcart') {
			if (AppStateModule.online) {
				const closeLoader = DialogService.openLoaderDialog();

				const removeData = _.filter(
					CartItemsModule.collection,
					(cartItemModel) => cartItemModel.quantity === 0 && !cartItemModel.shoppingcartitemid,
				);

				if (removeData.length) {
					const putModels = _.after(
						removeData.length,
						() => {
							this.proceedFromCart();
						},
					);
					_.each(
						removeData,
						(modelData) => {
							CartItemsModule
								.destroyModel({
									id: modelData.id,
									methodOptions: {
										auth: true,
										debug: {
											abort: true,
										},
									},
								})
								.then(
									() => {
										putModels();
									},
									() => {
										closeLoader();
									},
								);
						},
					);
				} else {
					this.proceedFromCart();
				}
			} else {
				// Notify user that (s)he needs to be online to go to the webshop
				const closeError = DialogService.openErrorDialog({
					header: {
						title: window.App.router.$t('dialogHeaderUnavailable'),
					},
					body: {
						content: window.App.router.$t('dialogTextUnavailableWebshop'),
					},
					footer: {
						buttons: [
							{
								id: 'accept',
								text: window.App.router.$t('dialogButtonUnavailableOk'),
								click: () => {
									closeError();
								},
							},
						],
					},
				});
			}
		} else if (source == 'editor') {
			const productModel = ProductStateModule.getProduct;

			if (!productModel) {
				throw new Error('Could not find required product model');
			}

			if (OfferingGroups(
				productModel.group,
				['BasicProducts'],
			)) {
				this.toEnd(productModel.id);
			} else {
				this.back(
					{},
					() => {
						this.openProduct(
							productModel.id,
							{
								trigger: true,
								replace: true,
							},
						);
					},
				);
			}
		} else if (source == 'preview' || source == 'overview') {
			const productModel = ProductStateModule.getProduct;
			if (!productModel) {
				throw new Error('Could not find required product model');
			}

			if (OfferingGroups(
				productModel.group,
				['PageSets'],
			)
				&& !OfferingGroups(
					productModel.group,
					['PhotoSheets'],
				)
				&& ProductStateModule.getCroppedPages.length
			) {
				this.toCrop(productModel.id);
			} else {
				ProductState.finalize(
					true,
					false,
				).then((saved) => {
					if (saved) {
						this.toEnd(productModel.id);
					}
				}).catch((err) => {
					if (err?.message) {
						DialogService.openErrorDialog({
							body: {
								content: err.message,
							},
						});
					}
				});
			}
		} else if (source == 'crop') {
			const productModel = ProductStateModule.getProduct;
			if (!productModel) {
				throw new Error('Could not find required product model');
			}

			ProductState.finalize(
				true,
				false,
			).then((saved) => {
				if (saved) {
					this.toEnd(productModel.id);
				}
			}).catch((err) => {
				if (err?.message) {
					DialogService.openErrorDialog({
						body: {
							content: err.message,
						},
					});
				}
			});
		} else if (source == 'end') {
			if (!ConfigModule.hasWebshopModule) {
				this.toExternalWebshop();
			} else {
				this.toCart();
			}
		}
	}

	public openProduct(
		productid: number,
		routeOpts?: RouteOptions,
		navigateOpts?: NavigateOptions,
	) {
		const productModel = ProductStateModule.getProduct;
		if (!productModel) {
			throw new Error('Could not find required product model');
		}

		const routeOptions = _.extend(
			{
				trigger: true,
			},
			routeOpts,
		);

		if (
			OfferingGroups(
				productModel.group,
				['BookTypes', 'PageSets'],
			)
		) {
			this.toOverview(
				productid,
				undefined,
				routeOptions,
				navigateOpts,
			);
		} else if (
			OfferingGroups(
				productModel.group,
				['Cards'],
			)
		) {
			this.toPreview(
				productid,
				undefined,
				routeOptions,
				navigateOpts,
			);
		} else {
			this.toEditor(
				productid,
				'0',
				routeOptions,
				navigateOpts,
			);
		}
	}

	public toStart(
		routeOpts?: RouteOptions,
		opts?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		const options = _.extend(
			{ slideDirection: 'right' },
			opts,
		);

		this.go(
			'',
			routeOptions,
			options,
		);
	}

	public toCreate(
		objParameters: {
			themeid?: number;
			offeringid?: number;
			themecatid?: number;
			listeritemid?: number;
		},
		routeOpts?: RouteOptions,
		options?: NavigateOptions,
	) {
		objParameters = _.extend(
			{},
			objParameters,
		);
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		let route = '';
		if (objParameters.hasOwnProperty('themeid') && objParameters.themeid !== null) {
			route += `create/${objParameters.listeritemid}/${objParameters.offeringid}/${objParameters.themecatid || 0}/${objParameters.themeid}`;
		} else if (objParameters.hasOwnProperty('themecatid') && objParameters.themecatid !== null) {
			route += `create/${objParameters.listeritemid}/${objParameters.offeringid}/${objParameters.themecatid}`;
		} else if (objParameters.hasOwnProperty('offeringid') && objParameters.offeringid !== null) {
			route += `create/${objParameters.listeritemid}/${objParameters.offeringid}`;
		} else if (objParameters.hasOwnProperty('listeritemid') && objParameters.listeritemid !== null) {
			route += `create/${objParameters.listeritemid}`;
		} else {
			route += 'create';
		}

		route = this.addUrlParameters(route);

		this.go(
			route,
			routeOptions,
			options,
		);
	}

	public toPDP(
		pdpid: DB.PDPModel['id'],
		options?: Record<string, any>,
		routeOpts?: RouteOptions,
		navigateOpts?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		const route = this.addUrlParameters(`pdp/${pdpid}`);
		this.go(
			route,
			routeOptions,
			navigateOpts,
		);
	}

	public toLibrary(
		productid: number,
		photoid?: number,
		routeOpts?: RouteOptions,
		options?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		const route = typeof photoid !== 'undefined'
			? `${productid}/library/${photoid}`
			: `${productid}/library`;
		this.go(
			route,
			routeOptions,
			options,
		);
	}

	public toSelect(
		productid: number,
		options: {
			channel?: ChannelModel['id'];
			// Do we open up the native screen with displaying the current selection?
			showSelection: boolean;
			// Should we remove unselected photos in the native app from the product?
			removeUnselected: boolean;
		},
		routeOpts?: RouteOptions,
		navigateOpts?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		const route = options && options.channel
			? `${productid}/select/${options.channel}`
			: `${productid}/select`;
		this.go(
			route,
			routeOptions,
			navigateOpts,
		);

		if (window.glPlatform == 'native') {
			const { themeModel } = ThemeStateModule;
			const maxPhotos = themeModel && themeModel.maxphotos
				? themeModel.maxphotos - ProductStateModule.getPhotosSelected.length
				: undefined;

			upload.filePicker(
				maxPhotos,
				{
					showSelection: !!(options && options.showSelection),
					removeUnselected: !!(options && options.removeUnselected),
				},
			);
		}
	}

	public toOverview(
		productid: number,
		pagenr?: string,
		routeOpts?: RouteOptions,
		options?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		this.go(
			`${productid}/overview`,
			routeOptions,
			options,
		);
	}

	public toPreview(
		productid: number,
		pagenr?: string,
		routeOpts?: RouteOptions,
		options?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		const productModel = ProductsModule.getById(productid);

		if (typeof pagenr === 'undefined' || pagenr === null) {
			if (productModel && OfferingGroups(
				productModel.group,
				['BookTypes'],
			)) {
				pagenr = '0-1';
			} else if (productModel && OfferingGroups(
				productModel.group,
				['Cards'],
			)) {
				pagenr = '1';
			} else {
				pagenr = '0';
			}
		}

		this.go(
			`${productid}/preview/${pagenr}`,
			routeOptions,
			options,
		);
	}

	public toEditor(
		productid: number,
		pagenr: string,
		routeOpts?: RouteOptions,
		opts?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		const options = _.extend(
			{ slideDirection: 'up' },
			opts,
		);

		this.go(
			`${productid}/edit/${pagenr}`,
			routeOptions,
			options,
		);
	}

	public toSort(
		productid: number,
		routeOpts?: RouteOptions,
		opts?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		const options = _.extend(
			{ slideDirection: 'up' },
			opts,
		);

		this.go(
			`${productid}/sort`,
			routeOptions,
			options,
		);
	}

	public toCrop(
		productid: number,
		objectid?: string,
		routeOpts?: RouteOptions,
		opts?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		const options = _.extend(
			{ slideDirection: 'up' },
			opts,
		);

		let route = `${productid}/crop`;
		if (objectid) {
			route += `/${objectid}`;
		}

		this.go(
			route,
			routeOptions,
			options,
		);
	}

	public toEnd(
		productid: number,
		routeOpts?: RouteOptions,
		options?: NavigateOptions,
	) {
		const productModel = ProductStateModule.getProduct;
		const countryModel = UserModule.countryid
			? AppDataModule.getCountry(UserModule.countryid)
			: undefined;

		if (productModel && countryModel) {
			this.enhancementCheck()
				.finally(
					() => this.offeringOptionsCheck(
						false,
					),
				)
				.finally(() => {
					const routeOptions = _.extend(
						{ trigger: true },
						routeOpts,
					);
					this.go(
						`${productid}/end`,
						routeOptions,
						options,
					);
				});
		} else {
			const routeOptions = _.extend(
				{ trigger: true },
				routeOpts,
			);
			this.go(
				`${productid}/end`,
				routeOptions,
				options,
			);
		}
	}

	public toConfirm(
		productid: number,
		routeOpts?: RouteOptions,
		options?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		this.go(
			`${productid}/confirm`,
			routeOptions,
			options,
		);
	}

	public toCart(routeOpts?: RouteOptions) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		this.go(
			'cart',
			routeOptions,
		);
	}

	public toWebshop(routeOpts?: RouteOptions) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		this.go(
			'webshop',
			routeOptions,
		);
	}

	public toWebshopConfirm(routeOpts?: RouteOptions) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		this.go(
			'webshop/confirm',
			routeOptions,
		);
	}

	public toPayment(routeOpts?: RouteOptions) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);
		this.go(
			'webshop/payment',
			routeOptions,
		);
	}

	public toWebshopSuccess(
		orderid: number,
		routeOpts?: RouteOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		if (orderid) {
			this.go(
				`webshop/success/${orderid}`,
				routeOptions,
			);
		} else {
			this.go(
				'webshop/success',
				routeOptions,
			);
		}
	}

	public toWebshopFailed(
		orderid: number,
		routeOpts?: RouteOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		if (orderid) {
			this.go(
				`webshop/failed/${orderid}`,
				routeOptions,
			);
		} else {
			this.go(
				'webshop/failed',
				routeOptions,
			);
		}
	}

	public toWebshopPending(
		orderid: number,
		routeOpts?: RouteOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		if (orderid) {
			this.go(
				`webshop/pending/${orderid}`,
				routeOptions,
			);
		} else {
			this.go(
				'webshop/pending',
				routeOptions,
			);
		}
	}

	private toExternalWebshop() {
		ProductState
			.finalize(
				true,
				false,
			)
			.then((saved) => {
				if (saved) {
					const closeLoader = DialogService.openLoaderDialog();

					User
						.save()
						.then(() => CartModule.snapshot())
						.then((cartItemModels) => (
							CartItemsModule.reset({
								data: cartItemModels,
							})
						))
						.then(User.syncExternalCart)
						.then(() => {
							if (CartItemsModule.collection.length === 0) {
								const closeError = DialogService.openErrorDialog({
									body: {
										content: window.App.router.$t('dialogTextCartContentChanged'),
									},
									footer: {
										buttons: [
											{
												id: 'accept',
												text: window.App.router.$t('dialogButtonErrorOk'),
												click: () => {
													window.location.reload();
													closeError();
												},
											},
										],
									},
								});

								throw new Error('No items in cart');
							}
						})
						.then(() => {
							const hyperlinkModel = AppDataModule.findHyperlink({ tag: 'cartApi' });

							if (!hyperlinkModel?.url) {
								return undefined;
							}

							return ajax
								.request(
									{
										method: 'delete',
										withCredentials: true,
										url: hyperlinkModel.url,
									},
									{
										auth: false,
									},
								)
								.catch(() => {
									// Ignore
								});
						})
						.then(() => {
							const externalWebhopEndpoint = ConfigModule['webshop.external.endpoint'];
							if (!externalWebhopEndpoint) {
								throw new Error('Missing external webshop endpoint setting');
							}

							const headers: AxiosRequestHeaders = {} as AxiosRequestHeaders;

							if (window.glPlatform === 'native') {
								if (
									window.nativeDeviceDetails
									&& window.nativeDeviceDetails.appVersion
								) {
									headers['X-Native-App-Version'] = window.nativeDeviceDetails.appVersion;
								} else if (typeof window.glBugsnagClient !== 'undefined') {
									const err = (
										window.nativeDeviceDetails
											? new Error('Could not find nativeDeviceDetails')
											: new Error('Could not find appVersion in nativeDeviceDetails')
									);
									window.glBugsnagClient.notify(
										err,
										(event) => { event.severity = 'warning'; },
									);
								}
							}

							const requestOptions: AxiosRequestConfig = {
								url: externalWebhopEndpoint,
								method: ConfigModule['webshop.external.method'].toLocaleLowerCase(),
								headers,
							};
							if (ConfigModule['webshop.external.method'] === 'POST') {
								requestOptions.data = {
									cart: CartModule.getState,
									locale: window.locale,
								};
							}

							return ajax
								.request(
									requestOptions,
									{
										auth: true,
										debug: {
											dialog: true,
											abort: true,
										},
									},
								)
								.catch((error) => {
									if (error.response?.status === 400) {
										CartItemsModule.reset();
									}
								});
						})
						.then((response) => {
							if (!response?.data) {
								throw new Error('Missing response data');
							}

							if (!response.data.hasOwnProperty('url')) {
								throw new Error('Missing url property in response');
							} else {
								openUrl(
									response.data.url,
									false,
								);

								if (window.glPlatform === 'native') {
									closeLoader();
									this.toStart();
								}
							}
						})
						.catch(() => {
							// Close load dialog
							closeLoader();

							if (!AppStateModule.online) {
								// Show offline message to user
								DialogService.openErrorDialog({
									header: {
										title: window.App.router.$t('dialogHeaderOffline'),
									},
									body: {
										content: window.App.router.$t('dialogTextOffline'),
									},
								});
							}
						});
				}
			})
			.catch((err) => {
				if (err?.message) {
					DialogService.openErrorDialog({
						body: {
							content: err.message,
						},
					});
				}
			});
	}

	public toUserAccount(
		routeOpts?: RouteOptions,
		options?: NavigateOptions,
	) {
		const hyperlinkModel = AppDataModule.findHyperlink({ tag: 'account' });

		if (hyperlinkModel) {
			if (hyperlinkModel.delegate) {
				const closeLoader = DialogService.openLoaderDialog();

				getDelegateUrl(hyperlinkModel.url)
					.then((delegateUrl) => {
						openWindow(
							delegateUrl,
							{
								width: undefined,
								height: undefined,
								inApp: true,
							},
						);
					})
					.finally(closeLoader);
			} else {
				openWindow(
					hyperlinkModel.url,
					{
						width: undefined,
						height: undefined,
						inApp: true,
					},
				);
			}
		} else {
			const routeOptions = _.extend(
				{ trigger: true },
				routeOpts,
			);
			this.go(
				'account',
				routeOptions,
				options,
			);
		}
	}

	public toUserAccountPhotos(
		routeOpts?: RouteOptions,
		options?: NavigateOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		this.go(
			'account/photos',
			routeOptions,
			options,
		);
	}

	public toOrderDetails(
		orderid: number,
		routeOpts?: RouteOptions,
	) {
		const routeOptions = _.extend(
			{ trigger: true },
			routeOpts,
		);

		this.go(
			`account/order/${orderid}`,
			routeOptions,
		);
	}

	private offeringOptionsCheck(
		skipFrame: boolean,
	): Promise<void> {
		// Get the data for the active project
		const productModel = ProductStateModule.getProduct;
		if (!productModel) {
			throw new Error('Missing required product model');
		}

		// Get the country model for the active user
		const countryModel = UserModule.countryid
			? AppDataModule.getCountry(UserModule.countryid)
			: undefined;
		if (!countryModel) {
			throw new Error('Missing required country model');
		}

		// Find the offering details related to the active project
		const searchProps: Partial<DB.OfferingModel> = {
			groupid: productModel.group,
			typeid: productModel.typeid,
		};
		if (productModel.variantid) {
			searchProps.variantid = productModel.variantid;
		}
		const offeringTypes = AppDataModule.findOffering(searchProps);
		const offeringModel = offeringTypes[0];
		const activeOptionValueModels = AppDataModule.getOfferingOptionValueModels(
			offeringModel.id,
			countryModel.regionid,
		);

		// Get all ids of the relevant offering options
		const offeringOptions = activeOptionValueModels.map(
			(model) => model.offeringoptionid,
		);

		// Get all offering options with multiple values, so user can pick
		const optionIds = _.uniq(offeringOptions).filter(
			(optionId) => offeringOptions.filter((x) => x == optionId).length > 1,
		);

		// Filter option models by the ones that should be shown after edit
		const filteredOptionIds = optionIds.filter((id) => {
			const optionModel = AppDataModule.offeringoptions.find(
				(model) => model.id === id,
			);
			return optionModel
				&& optionModel.showafteredit
				&& (!skipFrame || optionModel.tag !== 'frame');
		});

		// If there are not choices to make, we end this function execution
		if (filteredOptionIds.length === 0) {
			return Promise.resolve();
		}

		if (
			OfferingGroups(
				productModel.group,
				['BasicProducts'],
			)
		) {
			const pageModel = ProductStateModule.getPageByNumber(0);
			const frameOptionId = filteredOptionIds.find((optionId) => {
				const optionModel = AppDataModule.offeringoptions.find(
					(model) => model.id === optionId,
				);
				return optionModel?.tag === 'frame';
			});
			if (frameOptionId) {
				const frameOptionValues = AppDataModule.offeringoptionvalues.filter(
					(model) => model.offeringoptionid === frameOptionId,
				);
				const noneFrameOptionValue = frameOptionValues.find(
					(model) => model.tag === 'none',
				);
				if (noneFrameOptionValue && frameOptionValues.length > 1) {
					const isCurrentlyNone = AppDataModule.offeringoptionvalueofferinglinks.find(
						(model) => model.offeringid === offeringModel.id
							&& model.offeringoptionvalueid === noneFrameOptionValue.id,
					);
					if (isCurrentlyNone) {
						return new Promise((resolve) => {
							const { close: closeDialog } = DialogService.openDialog({
								header: {
									hasCloseButton: false,
								},
								body: {
									component: FramePickerView,
									props: {
										pageModel,
									},
									listeners: {
										closeDialog: () => {
											closeDialog();
										},
										select: (event: ServiceEvent<DB.OfferingOptionValueModel>) => {
											analytics.trackEvent(
												'Select upgrade frame',
												{
													groupid: productModel.group,
													typeid: productModel.typeid,
													value: event.payload.value,
												},
											);
										},
									},
									styles: {
										padding: '0',
									},
								},
								listeners: {
									close: () => {
										this
											.offeringOptionsCheck(true)
											.then((resolve));
									},
								},
								maxScreenSize: true,
								styles: {
									padding: '0',
								},
								width: 750,
							});
						});
					}
				}

				return this.offeringOptionsCheck(true);
			}
		}

		return new Promise((resolve) => {
			const { close: closeDialog } = DialogService.openDialog({
				header: {
					hasCloseButton: false,
					title: window.App.router.$t('dialogHeaderProductOptions'),
				},
				body: {
					component: OfferingOptionsView,
					props: {
						filterTags: ['frame'],
						showAfterEdit: true,
						showDuringEdit: false,
					},
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: window.App.router.$t('buttonSave'),
							click: () => {
								closeDialog();
							},
						},
					],
				},
				listeners: {
					close: () => {
						resolve();
					},
				},
				width: 350,
			});
		});
	}

	restore() {
		// Get app state from local storage
		const storageObject = storage.get('appState');
		// Remove local storage object
		storage.clear(['appState']).finally(() => {
			// Proceed to previous route
			if (storageObject) {
				this.go(
					storageObject.route,
					{ trigger: true, replace: true },
				);
			}
		});
	}
}

export default new Navigate();
