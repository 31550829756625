import './defines';
import ButtonComponent from 'components/button';
import { ServiceEvent } from 'services/service-event';
import {
	dom as domUtils,
	vue as vueUtils,
} from 'utils';
import { Public } from 'utils/decorators';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import { type ComponentPublicInstance } from 'vue';
import {
	Prop,
	Ref,
	Vue,
} from 'vue-facing-decorator';
import { type Cons } from 'vue-facing-decorator/dist/component';
import Template from './template.vue';

@Component({
	name: 'DialogComponent',
	components: {
		ButtonComponent,
	},
	emits: ['close'],
	mixins: [Template],
})
class DialogComponent<
	BodyComponent extends Cons,
	FooterComponent extends Cons,
	HeaderComponent extends Cons,
> extends Vue {
	@Prop({
		default: undefined,
		type: Function,
	})
	public readonly beforeClose?: ServiceEventHandler<any>;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly body!: DialogServiceOptionsBody<BodyComponent>;

	@Prop({
		default: 24,
		description: 'Defines the border radius of the dialog',
		type: Number,
	})
	public readonly borderRadius?: number;

	@Prop({
		default: () => [],
		type: [Array, Object, String],
	})
	public readonly classes!: string[] | string | Record<string, boolean>;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly footer!: DialogNewServiceOptionsFooter<FooterComponent>;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly header!: DialogServiceOptionsHeader<HeaderComponent>;

	@Prop({
		default: true,
		description: 'Indicates if the dialog should shown as a modal',
		type: Boolean,
	})
	public readonly isModal!: boolean;

	@Prop({
		default: () => ({}),
		type: Object,
	})
	public readonly listeners!: Record<string, ServiceEventHandler<any>[] | ServiceEventHandler<any>>;

	@Prop({
		default: () => ([16, 20]),
		description: 'Defines the padding of the dialog',
		type: [Array, String],
	})
	public readonly padding?: number[] | string | null;

	@Prop({
		default: 0,
		description: 'Defines the minimum spacing between the dialog and the screen',
		type: Number,
	})
	public readonly spacing!: number;

	@Prop({
		default: () => ({}),
		type: Object,
	})
	public readonly styles!: Partial<CSSStyleDeclaration> & Record<string, string>;

	@Prop({
		default: 350,
		type: [Number, String],
	})
	public readonly width?: number | string | null;

	protected get buttonBinding(): (button: DialogNewServiceButton) => Omit<DialogNewServiceButton, 'click' | 'icon' | 'id' | 'text'> {
		return (button) => {
			const {
				/* eslint-disable @typescript-eslint/no-unused-vars */
				click,
				icon,
				id,
				text,
				/* eslint-enable @typescript-eslint/no-unused-vars */
				...binding
			} = button;

			return binding;
		};
	}

	protected get computedStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {
			...this.styles as any,
		};

		if (this.borderRadius) {
			styles.borderRadius = `${this.borderRadius}px`;
		}

		if (!styles.maxHeight) {
			styles['--dialog-component-max-height'] = `${this.maxHeight - this.spacing}px`;
		} else {
			styles['--dialog-component-max-height'] = styles.maxHeight;
		}

		if (!styles.maxWidth) {
			styles.maxWidth = `${this.maxWidth - this.spacing}px`;
		}

		if (Array.isArray(this.padding)) {
			styles['--dialog-component-padding-x'] = `${this.padding[1]}px`;
			styles['--dialog-component-padding-y'] = `${this.padding[0]}px`;
		} else if (this.padding) {
			styles['--dialog-component-padding-x'] = this.padding;
			styles['--dialog-component-padding-y'] = this.padding;
		}

		if (this.width) {
			let { width } = this;

			if (typeof width === 'string') {
				const widthNumber = parseFloat(width);
				const widthUnit = width.replace(
					widthNumber.toString(),
					'',
				);

				if (widthUnit === '%') {
					width = Math.round((widthNumber / 100) * this.maxWidth);
				} else {
					width = widthNumber;
				}
			}

			width = Math.min(
				width,
				this.maxWidth,
			);
			styles.width = typeof this.width === 'number'
				? `${this.width}px`
				: this.width;
		}

		return styles;
	}

	protected get bodyComponentInstanceAPI(): NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>> | undefined {
		const bodySlotComponentInstance = this.$getSlot<InstanceType<BodyComponent>>('body');

		if (bodySlotComponentInstance) {
			return vueUtils.getInstanceAPI(bodySlotComponentInstance);
		}

		return undefined;
	}

	protected get footerComponentInstanceAPI(): NonVuePublicProps<ComponentPublicInstance<InstanceType<FooterComponent>>> | undefined {
		const footerSlotComponentInstance = this.$getSlot<InstanceType<FooterComponent>>('footer');

		if (footerSlotComponentInstance) {
			return vueUtils.getInstanceAPI(footerSlotComponentInstance);
		}

		return undefined;
	}

	protected get hasHeader(): boolean {
		return !!(
			'component' in this.header
			|| 'title' in this.header
			|| (
				'hasCloseButton' in this.header
				&& this.header.hasCloseButton
			)
		);
	}

	protected get hasFooter(): boolean {
		return !!(
			'component' in this.footer
			|| 'buttons' in this.footer
		);
	}

	protected get headerComponentInstanceAPI(): NonVuePublicProps<ComponentPublicInstance<InstanceType<HeaderComponent>>> | undefined {
		const headerSlotComponentInstance = this.$getSlot<InstanceType<HeaderComponent>>('header');

		if (headerSlotComponentInstance) {
			return vueUtils.getInstanceAPI(headerSlotComponentInstance);
		}

		return undefined;
	}

	@Ref('dialog')
	private dialogElement!: HTMLDivElement;

	@Ref('dialogBody')
	private dialogBodyElement!: HTMLDivElement;

	@Ref('dialogFooter')
	private dialogFooterElement?: HTMLDivElement;

	@Ref('dialogHeader')
	private dialogHeaderElement?: HTMLDivElement;

	private bodyChildrenChangeListenerDisconnect?: () => void;

	protected bodyStyles: Partial<CSSStyleDeclaration> = {};

	private calculatingBodyStyles!: boolean;

	private maxHeight = window.innerHeight;

	private maxWidth = window.innerWidth;

	private resizeObserver?: ResizeObserver;

	protected beforeUnmount(): void {
		this.bodyChildrenChangeListenerDisconnect?.();
		this.bodyChildrenChangeListenerDisconnect = undefined;
		this.resizeObserver?.disconnect();
		this.resizeObserver = undefined;
	}

	protected mounted(): void {
		requestAnimationFrame(() => {
			this.bodyChildrenChangeListenerDisconnect = domUtils.onElementChildrenChange(
				this.dialogBodyElement,
				() => this.$nextTick(this.calculateBodyComputedStyles),
			);
		});
		this.resizeObserver = new ResizeObserver(() => {
			this.maxHeight = window.innerHeight;
			this.maxWidth = window.innerWidth;
			this.$nextTick(() => this.calculateBodyComputedStyles());
		});
		this.resizeObserver.observe(document.body);
		this.calculateBodyComputedStyles();
	}

	@Public()
	public bodyComponent(): Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>>> | undefined {
		return this.bodyComponentInstanceAPI as Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>>> | undefined;
	}

	private calculateBodyComputedStyles(): void {
		if (
			this.calculatingBodyStyles
			|| this.$currentInstance.isUnmounted
			|| !this.bodyChildrenChangeListenerDisconnect
		) {
			return;
		}

		this.calculatingBodyStyles = true;
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {
			...(this.body.styles || {}),
		};

		if (!styles.maxHeight) {
			this.bodyStyles.maxHeight = 'initial';
			const calculateHeight = (tries = 0): void => {
				this.$nextTick(() => {
					if (
						this.$currentInstance.isUnmounted
						|| !this.bodyChildrenChangeListenerDisconnect
					) {
						return;
					}

					const dialogRect = this.dialogElement?.getBoundingClientRect();
					let dialogHeight = (
						dialogRect?.height
						|| 0
					);

					if (
						dialogHeight === 0
						&& tries < 3
					) {
						calculateHeight(tries + 1);
					} else if (dialogHeight === 0) {
						return;
					}

					const dialogStyles = getComputedStyle(this.dialogElement);
					const dialogHeaderRect = this.dialogHeaderElement?.getBoundingClientRect();
					let dialogHeaderHeight = (
						dialogHeaderRect?.height
						|| 0
					);
					const dialogHeaderStyles = (
						this.dialogHeaderElement
							? getComputedStyle(this.dialogHeaderElement)
							: {} as CSSStyleDeclaration
					);

					if (
						dialogStyles.paddingTop
						|| dialogStyles.paddingBottom
					) {
						dialogHeight -= parseFloat(dialogStyles.paddingTop);
						dialogHeight -= parseFloat(dialogStyles.paddingBottom);
					}

					if (
						this.dialogHeaderElement
						&& (
							dialogHeaderStyles.paddingTop
							|| dialogHeaderStyles.paddingBottom
							|| dialogHeaderStyles.marginTop
							|| dialogHeaderStyles.marginBottom
						)
					) {
						dialogHeaderHeight += parseFloat(dialogHeaderStyles.paddingTop);
						dialogHeaderHeight += parseFloat(dialogHeaderStyles.paddingBottom);
						dialogHeaderHeight += parseFloat(dialogHeaderStyles.marginTop);
						dialogHeaderHeight += parseFloat(dialogHeaderStyles.marginBottom);
					}

					const dialogFooterRect = this.dialogFooterElement?.getBoundingClientRect();
					let dialogFooterHeight = (
						dialogFooterRect?.height
						|| 0
					);
					const dialogFooterStyles = (
						this.dialogFooterElement
							? getComputedStyle(this.dialogFooterElement)
							: {} as CSSStyleDeclaration
					);

					if (
						this.dialogFooterElement
						&& (
							dialogFooterStyles.paddingTop
							|| dialogFooterStyles.paddingBottom
							|| dialogFooterStyles.marginTop
							|| dialogFooterStyles.marginBottom
						)
					) {
						dialogFooterHeight += parseFloat(dialogFooterStyles.paddingTop);
						dialogFooterHeight += parseFloat(dialogFooterStyles.paddingBottom);
						dialogFooterHeight += parseFloat(dialogFooterStyles.marginTop);
						dialogFooterHeight += parseFloat(dialogFooterStyles.marginBottom);
					}

					styles.maxHeight = `${Math.ceil(dialogHeight - dialogHeaderHeight - dialogFooterHeight) + 1}px`;

					if (styles.maxHeight) {
						styles['--dialog-component-body-max-height'] = styles.maxHeight;
					}

					this.bodyStyles = styles;

					setTimeout(() => {
						this.$nextTick(() => {
							this.calculatingBodyStyles = false;
						});
					});
				});
			};
			calculateHeight();
			return;
		}

		if (styles.maxHeight) {
			styles['--dialog-component-body-max-height'] = styles.maxHeight;
		}

		this.bodyStyles = styles;
		this.calculatingBodyStyles = false;
	}

	@Public()
	public footerComponent(): Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<FooterComponent>>>> | undefined {
		return this.footerComponentInstanceAPI as Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<FooterComponent>>>> | undefined;
	}

	protected getClasses(
		classes?: string[] | string | Record<string, boolean>,
	): Record<string, boolean> {
		const newClasses: Record<string, boolean> = {};

		if (classes) {
			if (Array.isArray(classes)) {
				// eslint-disable-next-line no-restricted-syntax
				for (const footerClass of classes) {
					newClasses[footerClass] = true;
				}
			} else if (typeof classes === 'string') {
				newClasses[classes] = true;
			} else {
				Object.assign(
					newClasses,
					classes,
				);
			}
		}

		return newClasses;
	}

	@Public()
	public headerComponent(): Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<HeaderComponent>>>> | undefined {
		return this.headerComponentInstanceAPI as Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<HeaderComponent>>>> | undefined;
	}

	@Public()
	public onCloseClick(event?: ServiceEvent): Decorated<void> {
		if (!event) {
			event = new ServiceEvent({
				type: 'close',
			});
		}

		if (this.beforeClose) {
			this.beforeClose(event);
		}

		if (!event.defaultPrevented) {
			this.$emit('close');
		}
	}

	protected onFooterButtonClick(
		buttonId: string,
		event: MouseEvent,
	): void {
		if ('buttons' in this.footer) {
			const footerButton = this.footer.buttons?.find((button) => button.id === buttonId);
			footerButton?.click?.call(
				this,
				event as any,
			);
		}
	}
}

export default toNative(DialogComponent);
