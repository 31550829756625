import _ from 'underscore';
import merge from 'deepmerge';
import {
	ChannelModel,
	ConnectorBridgeResponseData,
	ChannelProfileModel,
} from 'interfaces/app';
import filterText from '../tools/filter-text';
import { ChannelsModule, ConfigModule } from '../store/index';
import openWindow from '../ops/open-window';
import parseUrl from '../tools/parse-url';
import ajax from '../controllers/ajax';
import connector, {
	ConnectorBridge,
	ConnectorBridgeLoginOptions,
	ConnectorBridgeLoginResponse,
	ConnectorBridgeMeOptions,
	ConvertedPhotoData,
} from '../controllers/connector';

interface MediaData {
	id: string;
	thumbnail_url?: string;
	caption?: string;
	timestamp?: string;
	media_type?: 'IMAGE'|'VIDEO'|'CAROUSEL_ALBUM';
	media_url: string;
	permalink?: string;
	username?: string;
}

class InstagramClass implements ConnectorBridge {
	private code: string|null = null;

	public contentFilters: string[] = [];

	public grantedScopes: string[] = [];

	public isSupported = true;

	private display = 'popup';

	private network: ChannelModel['id'] = 'instagram';

	public setup() {
		return Promise.resolve();
	}

	public init(channelModel: ChannelModel) {
		if (channelModel.apikey) {
			// Use redirect method when inside the Facebook app
			this.display = window.App.standalone ? 'page' : 'popup';

			return Promise.resolve();
		}

		return Promise.reject(new Error('Missing api key'));
	}

	public login(
		scopes: string[],
		options: ConnectorBridgeLoginOptions,
	): Promise<ConnectorBridgeLoginResponse> {
		const display = options && options.display
			? options.display
			: this.display;

		return new Promise((resolve, reject) => {
			const channelModel = ChannelsModule.getById('instagram');
			if (!channelModel) {
				throw new Error('Could not find Instagram channel model');
			}

			if (display == 'none') {
				if (!connector.networks.instagram.accessToken) {
					reject(new Error('No active access_token from Instagram'));
				} else if (scopes.indexOf('user_media') >= 0) {
					this.photos({})
						.then(() => {
							scopes.forEach((scope) => {
								if (this.grantedScopes.indexOf(scope) === -1) {
									this.grantedScopes.push(scope);
								}
							});

							resolve({
								accessToken: connector.networks.instagram.accessToken || undefined,
								userid: channelModel.profile?.id || undefined,
							});
						})
						.catch(reject);
				} else {
					this.me(
						scopes,
						{},
					)
						.then((response: any) => {
							scopes.forEach((scope) => {
								if (this.grantedScopes.indexOf(scope) === -1) {
									this.grantedScopes.push(scope);
								}
							});

							resolve({
								accessToken: connector.networks.instagram.accessToken || undefined,
								userid: response.id,
							});
						})
						.catch(reject);
				}
			} else {
				const authUrl = `https://api.instagram.com/oauth/authorize
					?app_id=${channelModel.apikey}
					&redirect_uri=${options.redirectUri}
					&scope=${scopes.join(',')}
					&response_type=code`;

				let int = 0;
				let code = '';
				const popup = openWindow(
					authUrl,
					{},
					() => {
						if (int) {
							window.clearInterval(int);
						}

						if (code.length) {
							ajax.request(
								{
									method: 'post',
									url: '/api/instagram/access_token',
									data: {
										code,
										redirect_uri: options.redirectUri,
									},
								},
								{
									auth: true,
								},
							).then((response) => {
								if (response.data && response.data.user_id && response.data.access_token) {
									const returnData: {
										userid: string;
										accessToken?: string;
									} = {
										userid: response.data.user_id,
										accessToken: response.data.access_token,
									};

									resolve(returnData);
								} else {
									reject(new Error('Could not get access_token from Instagram'));
								}
							});
						} else {
							reject(new Error('No code received from Instagram'));
						}
					},
				);
				int = window.setInterval(
					() => {
						try {
							if (popup
							&& options.redirectUri
							&& popup.location.href.includes(options.redirectUri)
							) {
								const parsedUrl = parseUrl(popup.location.href);
								if (parsedUrl.parameters.code) {
									({ code } = parsedUrl.parameters);
								}
								popup.close();
							}
						} catch (e) {
						// Do nothing
						}
					},
					100,
				);
			}
		});
	}

	public logout() {
		return Promise.resolve();
	}

	private api(
		route: string,
		routeOptions?: Record<string, any>,
	): Promise<ConnectorBridgeResponseData> {
		return new Promise((resolve, reject) => {
			if (!connector.networks.instagram.accessToken) {
				throw new Error('Missing accessToken to perform api request');
			}

			const params = merge(
				{
					access_token: connector.networks.instagram.accessToken,
				},
				routeOptions || {},
			);

			ajax.request(
				{
					method: 'get',
					url: `https://graph.instagram.com/${route}`,
					params,
				},
				{
					auth: false,
				},
			).then((response) => {
				const returnData: ConnectorBridgeResponseData = {
					data: response.data.data ? response.data.data : response.data,
					paging: {},
				};

				if (response.data.paging
					&& response.data.paging.cursors
					&& response.data.paging.cursors.after
				) {
					returnData.paging.nextOptions = {
						after: response.data.paging.cursors.after,
					};
				}

				resolve(returnData);
			}).catch((err: Error) => {
				reject(this.parseError(err));
			});
		});
	}

	public me(
		scope: string[],
		options?: ConnectorBridgeMeOptions,
	): Promise<ChannelProfileModel> {
		return new Promise((resolve, reject) => {
			this.api(
				'me',
				options,
			).then(
				({ data }) => {
					const profile: ChannelProfileModel = {
						id: data.id,
					};
					if (data.username) {
						profile.display_name = data.username;
					}

					resolve(profile);
				},
				reject,
			);
		});
	}

	public folders() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public albums() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public albumPhotos() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public photos(options: any) {
		const defaults = {
			scope: '',
		};
		options = _.extend(
			defaults,
			options,
		);

		const route = options.nextPage ? options.nextPage : 'me/media';

		let routeOptions = {
			limit: 20,
			fields: 'caption,id,media_type,media_url,thumbnail_url,timestamp',
		};
		if (options.nextOptions) {
			routeOptions = merge(
				routeOptions,
				options.nextOptions,
			);
		}

		let returnData: ConnectorBridgeResponseData;
		return this.api(
			route,
			routeOptions,
		)
			.then((apiResponse) => {
				returnData = apiResponse;
				return this.processMediaRecords(returnData.data);
			})
			.then((data) => {
				returnData.data = data;
				return returnData;
			});
	}

	private processMediaRecords(mediaDataRecords: MediaData[]): Promise<MediaData[]> {
		return new Promise((resolve) => {
			let filteredData: MediaData[] = [];

			let p: Promise<MediaData[]|void> = Promise.resolve();
			mediaDataRecords.forEach((mediaData: MediaData) => {
				p = p.then((d) => {
					if (d) {
						filteredData = filteredData.concat(d);
					}

					return this.processMediaRecord(mediaData);
				});
			});
			p.then((d) => {
				if (d) {
					filteredData = filteredData.concat(d);
				}
			}).finally(() => {
				resolve(filteredData);
			});
		});
	}

	private processMediaRecord(mediaData: MediaData): Promise<MediaData[]|void> {
		return new Promise((resolve) => {
			if (mediaData.media_type === 'IMAGE') {
				resolve([mediaData]);
			} else if (mediaData.media_type === 'CAROUSEL_ALBUM') {
				this.api(
					`${mediaData.id}/children`,
					{
						fields: 'id,media_type,media_url,thumbnail_url,timestamp',
					},
				).then((childrenData) => {
					resolve(this.processMediaRecords(childrenData.data));
				}).catch(() => {
					resolve();
				});
			} else {
				resolve();
			}
		});
	}

	public convertFolderData() {
		throw new Error('Bridge function not implemented');
	}

	public convertAlbumData() {
		throw new Error('Bridge function not implemented');
	}

	public convertPhotoData(mediaData: MediaData) {
		let title: string|null = null;
		if (mediaData.caption) {
			// only add title if at least 50% of it has latin characters
			const filteredTitle = filterText(
				mediaData.caption,
				ConfigModule.textEncodingSupport,
			);
			if (filteredTitle.length >= 0.5 * mediaData.caption.length) {
				title = filteredTitle;
			}
		}

		const objPhoto: ConvertedPhotoData = {
			source: this.network,
			externalId: String(mediaData.id),
			thumb_url: mediaData.thumbnail_url || mediaData.media_url,
			full_url: mediaData.media_url,
			photodate: mediaData.timestamp ? mediaData.timestamp : null,
			title,
		};

		return objPhoto;
	}

	public getFileType() {
		throw new Error('Bridge function not implemented');
	}

	public share() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	private parseError(err: Error) {
		if (err.message
			&& err.message == 'Application does not have permission for this action'
		) {
			return new Error(window.App.router.$t('errors.instagram.noAccessToMedia'));
		}

		return err;
	}
}

export default InstagramClass;
