import type { SplitNumberResult } from 'interfaces/app';

/**
 * Function to split a number into a random number of parts
 * @param total The total number to divide of the parts
 * @param numParts The number of parts to generate
 * @param maxVal The maximum value for each part
 * @returns array of parts with a number each
 */
export default function splitNumber(
	total: number,
	numParts: number,
	maxVal: number,
): SplitNumberResult {
	const result: SplitNumberResult = {
		parts: [],
		unused: 0,
	};
	let sum = 0;

	const partsToCreate = Math.min(
		total,
		numParts,
	);

	for (let i = 0; i < partsToCreate; i += 1) {
		/**
		 * Default number is what we have left from the total
		 */
		let number = total - sum;

		/**
		 * If this is not the last part in the sequence,
		 * we generate a random number between 1 and maxVal
		 */
		if (i < partsToCreate - 1) {
			const partsLeft = partsToCreate - i;
			const minimumPerPartLeft = Math.floor(
				number / partsLeft,
			);
			const maximumPerPartLeft = Math.ceil(
				number - (partsLeft - 1),
			);
			const randNumber = Math.floor(
				Math.random() * maxVal + 1,
			);

			/**
			 * This part will be assigned the random number,
			 * but only if it is greater than the minimum per part left
			 * and less than the maximum per part left
			 */
			number = Math.min(
				maximumPerPartLeft,
				Math.max(
					minimumPerPartLeft,
					randNumber,
				),
			);
		}

		if (number > maxVal) {
			/**
			 * If this is the last part in the sequence,
			 * we make sure it is not greater than maxVal
			 */
			number = Math.min(
				number,
				maxVal,
			);
		}

		// Add the number assigned to this part to the sum
		sum += number;

		// Add the number to the parts array
		result.parts.push(
			number,
		);
	}

	result.unused = total - sum;

	// Shuffle the array to randomize the parts
	// This to avoid ending up with all 1 values at the end
	for (let i = result.parts.length - 1; i > 0; i -= 1) {
		const j = Math.floor(Math.random() * (i + 1));
		[result.parts[i], result.parts[j]] = [result.parts[j], result.parts[i]];
	}

	return result;
}
