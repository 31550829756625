import './defines';
import type {
	TooltipCalculatePositionOptions,
	TooltipMainPositionSide,
	TooltipPosition,
	TooltipSecondaryPositionSide,
} from 'interfaces/app';
import { ServiceEvent } from 'services/service-event';
import {
	dom as domUtils,
	vue as vueUtils,
} from 'utils';
import { Public } from 'utils/decorators';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import { type ComponentPublicInstance } from 'vue';
import {
	Prop,
	Ref,
	Vue,
	type VueExtended,
	Watch,
} from 'vue-facing-decorator';
import { type Cons } from 'vue-facing-decorator/dist/component';
import Template from './template.vue';

@Component({
	name: 'TooltipComponent',
	emits: ['close'],
	mixins: [Template],
})
class TooltipComponent<BodyComponent extends Cons> extends (Vue as typeof VueExtended) {
	@Prop({
		description: 'Defines the anchor element of the tooltip',
		required: true,
		type: [HTMLElement, Object],
	})
	public readonly anchor!: HTMLElement | ComponentPublicInstance;

	@Prop({
		default: undefined,
		description: 'Defines the function that will be called before the tooltip is closed with the ability to prevent the close action',
		type: Function,
	})
	public readonly beforeClose?: ServiceEventHandler<any>;

	@Prop({
		description: "Defines the body component's options of the tooltip",
		required: true,
		type: Object,
	})
	public readonly body!: TooltipServiceOptionsBody<BodyComponent>;

	@Prop({
		default: () => ({}),
		description: 'Defines the CSS classes for the tooltip body',
		type: [Array, Object],
	})
	public readonly bodyClasses!: string[] | Record<string, boolean>;

	@Prop({
		default: () => ({}),
		description: 'Defines the CSS styles for the tooltip body',
		type: Object,
	})
	public readonly bodyStyles!: Partial<CSSStyleDeclaration>;

	@Prop({
		default: 8,
		description: 'Defines the border radius of the tooltip',
		type: Number,
	})
	public readonly borderRadius?: number;

	@Prop({
		default: false,
		description: 'Indicates if the tooltip should be closed when the user clicks outside of it',
		type: Boolean,
	})
	public readonly closeOnModalClick!: boolean;

	@Prop({
		description: 'Defines the distance between the anchor and the tooltip',
		required: true,
		type: [Number, String],
	})
	public readonly distance!: number | string;

	@Prop({
		default: true,
		description: 'Indicates if the tooltip have a close button',
		type: Boolean,
	})
	public readonly hasCloseButton!: boolean;

	@Prop({
		acceptedValues: [
			'bottom center',
			'bottom left',
			'bottom right',
			'right',
			'top center',
			'top left',
			'top right',
			'left',
		],
		default: 'bottom center',
		schema: 'TooltipPosition',
		type: String,
	})
	public readonly initialPosition!: TooltipPosition;

	@Prop({
		default: true,
		description: 'Indicates if the tooltip should shown as a modal',
		type: Boolean,
	})
	public readonly isModal!: boolean;

	@Prop({
		default: () => ({}),
		description: 'Defines the listeners for the tooltip body',
		type: Object,
	})
	public readonly listeners!: Record<string, ServiceEventHandler<any>[] | ServiceEventHandler<any>>;

	@Prop({
		default: false,
		description: "Indicates if the tooltip's top arrow should not be shown",
		type: Boolean,
	})
	public readonly noArrow!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the tooltip should not have any styles',
		type: Boolean,
	})
	public readonly noStyles!: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the title of the tooltip',
		type: String,
	})
	public readonly title?: string;

	@Prop({
		default: () => ({}),
		description: 'Defines the CSS classes for the tooltip element',
		type: [Array, Object],
	})
	public readonly tooltipClasses!: string[] | Record<string, boolean>;

	@Prop({
		default: () => ({}),
		description: 'Defines the CSS styles for the tooltip element',
		type: Object,
	})
	public readonly tooltipStyles!: Partial<CSSStyleDeclaration>;

	protected get bodyComponentInstanceAPI(): NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>> | undefined {
		const defaultSlotComponentInstance = this.$getSlot<InstanceType<BodyComponent>>('default');

		if (defaultSlotComponentInstance) {
			return vueUtils.getInstanceAPI(defaultSlotComponentInstance);
		}

		return undefined;
	}

	protected get computedAnchor(): HTMLElement {
		if ('_isVueClass' in this.anchor) {
			return this.anchor.$el as HTMLElement;
		}

		return this.anchor as HTMLElement;
	}

	protected get computedTooltipContainerClasses(): Record<string, boolean> {
		return {
			'tooltip-modal': this.isModal,
			[this.internalTheme]: true,
		};
	}

	protected get computedTooltipHeaderClasses(): Record<string, boolean> {
		return {
			'tooltip-header-has-title': !!this.title,
		};
	}

	protected get computedTooltipWrapperClasses(): Record<string, boolean> {
		const classes: Record<string, boolean> = {};

		if (Array.isArray(this.tooltipClasses)) {
			// eslint-disable-next-line no-restricted-syntax
			for (const className of this.tooltipClasses) {
				classes[className] = true;
			}
		} else {
			Object.assign(
				classes,
				this.tooltipClasses,
			);
		}

		if (!this.noArrow) {
			if (this.tooltipMainPositionSide === 'bottom') {
				classes['tooltip-wrapper-arrow-top'] = true;
			} else if (this.tooltipMainPositionSide === 'right') {
				classes['tooltip-wrapper-arrow-left'] = true;
			} else if (this.tooltipMainPositionSide === 'top') {
				classes['tooltip-wrapper-arrow-bottom'] = true;
			} else if (this.tooltipMainPositionSide === 'left') {
				classes['tooltip-wrapper-arrow-right'] = true;
			}
		}

		return {
			'tooltip-wrapper-no-arrow': this.noArrow,
			'tooltip-wrapper-no-styles': this.noStyles,
			...classes,
		};
	}

	protected get computedTooltipWrapperStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (
			!this.noStyles
			&& this.borderRadius
		) {
			styles.borderRadius = `${this.borderRadius}px`;
		}

		return {
			...styles,
			...this.tooltipStyles,
		};
	}

	protected get hasHeader(): boolean {
		return !!(
			this.title
			|| this.hasCloseButton
		);
	}

	@Ref('tooltipBody')
	private tooltipBodyElement!: HTMLDivElement;

	@Ref('tooltipPositioner')
	private tooltipPositionerElement!: HTMLDivElement;

	@Ref('tooltipWrapper')
	private tooltipWrapperElement!: HTMLDivElement;

	private isCalculatingPosition?: boolean;

	private isCheckingVisibility?: boolean;

	private mutationObserver?: MutationObserver;

	private resizeObserver?: ResizeObserver;

	protected tooltipPosition: Pick<CSSStyleDeclaration, 'left' | 'top' | 'visibility'> = {
		left: '0px',
		top: '0px',
		visibility: 'hidden',
	};

	private tooltipMainPositionSide: TooltipMainPositionSide | null = null;

	private tooltipSecondaryPositionSide: TooltipSecondaryPositionSide | null = null;

	protected beforeUnmount(): void {
		document.body.classList.remove('tooltip-modal-no-scroll');
		document.documentElement.classList.remove('tooltip-modal-no-scroll');
		this.resizeObserver?.disconnect();
		this.mutationObserver?.disconnect();
	}

	protected created(): void {
		this.resizeObserver = new ResizeObserver(() => this.calculatePosition());
		this.mutationObserver = new MutationObserver(() => this.calculatePosition());
		this.resizeObserver.observe(this.computedAnchor);
		this.addObserversToParent(this.computedAnchor);
	}

	protected mounted(): void {
		this.resizeObserver?.observe(this.tooltipWrapperElement);
		this.calculatePosition();
	}

	@Watch(
		'initialPosition',
		{
			immediate: true,
		},
	)
	protected onInitialPositionChange(): void {
		if (this.initialPosition.startsWith('bottom')) {
			this.tooltipMainPositionSide = 'bottom';
		} else if (this.initialPosition.startsWith('right')) {
			this.tooltipMainPositionSide = 'right';
		} else if (this.initialPosition.startsWith('top')) {
			this.tooltipMainPositionSide = 'top';
		} else if (this.initialPosition.startsWith('left')) {
			this.tooltipMainPositionSide = 'left';
		}

		if (this.initialPosition.endsWith('center')) {
			this.tooltipSecondaryPositionSide = 'center';
		} else if (this.initialPosition.endsWith('right')) {
			this.tooltipSecondaryPositionSide = 'right';
		} else if (this.initialPosition.endsWith('left')) {
			this.tooltipSecondaryPositionSide = 'left';
		} else if (this.initialPosition.endsWith('bottom')) {
			this.tooltipSecondaryPositionSide = 'bottom';
		} else if (this.initialPosition.endsWith('top')) {
			this.tooltipSecondaryPositionSide = 'top';
		}
	}

	@Watch(
		'isModal',
		{
			immediate: true,
		},
	)
	protected onIsModalChange(): void {
		if (!domUtils.isRectScrollable(this.computedAnchor)) {
			if (this.isModal) {
				document.body.classList.add('tooltip-modal-no-scroll');
				document.documentElement.classList.add('tooltip-modal-no-scroll');
			} else {
				document.documentElement.classList.remove('tooltip-modal-no-scroll');
				document.body.classList.remove('tooltip-modal-no-scroll');
			}
		}
	}

	private addObserversToParent(element: HTMLElement): void {
		this.resizeObserver?.observe(element);
		this.mutationObserver?.observe(
			element,
			{
				attributes: true,
			},
		);

		if (
			element !== window.document.body
			&& element.parentElement
		) {
			this.addObserversToParent(element.parentElement);
		}
	}

	@Public()
	public bodyComponent(): Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>>> | undefined {
		return this.bodyComponentInstanceAPI as Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>>> | undefined;
	}

	@Public()
	public bodyElement(): Decorated<HTMLDivElement> {
		return this.tooltipBodyElement as Decorated<HTMLDivElement>;
	}

	@Public()
	public calculatePosition(options?: TooltipCalculatePositionOptions): Decorated<Promise<void>> {
		if (this.isCalculatingPosition) {
			return Promise.resolve() as Decorated<Promise<void>>;
		}

		this.isCalculatingPosition = true;
		const finalOptions: Required<TooltipCalculatePositionOptions> = {
			mainSide: (
				this.tooltipMainPositionSide
				|| 'bottom'
			),
			secondarySide: (
				this.tooltipSecondaryPositionSide
				|| 'center'
			),
			...(options || {}),
		};

		return new Promise<void>((resolve, reject) => {
			requestAnimationFrame(() => {
				try {
					if (
						this.computedAnchor
						&& this.tooltipWrapperElement
						&& this.tooltipPositionerElement
					) {
						const anchorRect = this.computedAnchor.getBoundingClientRect();
						const tooltipRect = this.tooltipWrapperElement.getBoundingClientRect();
						const tooltipComputedStyles = getComputedStyle(this.tooltipWrapperElement);
						let tooltipWrapperElementWidth = this.tooltipWrapperElement.clientWidth;

						if (tooltipComputedStyles.marginLeft) {
							tooltipWrapperElementWidth += parseInt(
								tooltipComputedStyles.marginLeft,
								10,
							);
						}
						if (tooltipComputedStyles.marginRight) {
							tooltipWrapperElementWidth += parseInt(
								tooltipComputedStyles.marginRight,
								10,
							);
						}

						let leftPosition = 0;
						let topPosition = 0;

						if (finalOptions.mainSide === 'bottom') {
							if (typeof this.distance === 'number') {
								topPosition = Math.round(anchorRect.top + anchorRect.height + this.distance);
							} else if (this.distance.endsWith('%')) {
								const distance = parseInt(
									this.distance,
									10,
								);
								topPosition = Math.round(anchorRect.top + (anchorRect.height * (distance / 100)) - (tooltipRect.height / 2));
							}
						} else if (finalOptions.mainSide === 'right') {
							if (typeof this.distance === 'number') {
								leftPosition = Math.round(anchorRect.left + anchorRect.width + this.distance);
							} else if (this.distance.endsWith('%')) {
								const distance = parseInt(
									this.distance,
									10,
								);
								leftPosition = Math.round(anchorRect.left + (anchorRect.width * (distance / 100)) - (tooltipRect.width / 2));
							}
						} else if (finalOptions.mainSide === 'top') {
							if (typeof this.distance === 'number') {
								topPosition = Math.round(anchorRect.top - this.distance - tooltipRect.height);
							} else if (this.distance.endsWith('%')) {
								const distance = parseInt(
									this.distance,
									10,
								);
								topPosition = Math.round(anchorRect.top - (anchorRect.height * (distance / 100)) - (tooltipRect.height / 2));
							}
						} else if (finalOptions.mainSide === 'left') {
							if (typeof this.distance === 'number') {
								leftPosition = Math.round(anchorRect.left - this.distance - tooltipRect.width);
							} else if (this.distance.endsWith('%')) {
								const distance = parseInt(
									this.distance,
									10,
								);
								leftPosition = Math.round(anchorRect.left - (anchorRect.width * (distance / 100)) - (tooltipRect.width / 2));
							}
						}

						if (
							finalOptions.mainSide === 'bottom'
							|| finalOptions.mainSide === 'top'
						) {
							if (finalOptions.secondarySide === 'center') {
								leftPosition = Math.round(anchorRect.left + (anchorRect.width / 2) - (tooltipWrapperElementWidth / 2));
							} else if (finalOptions.secondarySide === 'left') {
								leftPosition = Math.round(anchorRect.left);
							} else if (finalOptions.secondarySide === 'right') {
								leftPosition = Math.round(anchorRect.left + anchorRect.width - tooltipWrapperElementWidth);
							}
						} else if (
							finalOptions.mainSide === 'right'
							|| finalOptions.mainSide === 'left'
						) {
							if (finalOptions.secondarySide === 'center') {
								topPosition = Math.round(anchorRect.top + (anchorRect.height / 2) - (tooltipRect.height / 2));
							} else if (finalOptions.secondarySide === 'top') {
								topPosition = Math.round(anchorRect.top);
							} else if (finalOptions.secondarySide === 'bottom') {
								topPosition = Math.round(anchorRect.top + anchorRect.height - tooltipRect.height);
							}
						}

						this.tooltipMainPositionSide = finalOptions.mainSide;
						this.tooltipSecondaryPositionSide = finalOptions.secondarySide;
						const newLeftPosition = `${Math.max(
							0,
							leftPosition,
						)}px`;
						const newTopPosition = `${Math.max(
							0,
							topPosition,
						)}px`;

						if (newLeftPosition !== this.tooltipPosition.left) {
							this.tooltipPosition.left = newLeftPosition;
						}
						if (newTopPosition !== this.tooltipPosition.top) {
							this.tooltipPosition.top = newTopPosition;
						}

						requestAnimationFrame(() => resolve());
					} else {
						resolve();
					}
				} catch (error) {
					reject(error);
				}
			});
		})
			.finally(() => {
				this.isCalculatingPosition = false;

				return this.checkVisibility();
			}) as Decorated<Promise<void>>;
	}

	private checkVisibility(): Promise<void> {
		if (this.isCheckingVisibility) {
			return Promise.resolve();
		}

		this.isCheckingVisibility = true;

		if (this.tooltipPositionerElement) {
			return new Promise<void>((resolve, reject) => {
				requestAnimationFrame(async () => {
					try {
						const isFullyVisibleResult = domUtils.isFullyVisible(this.tooltipPositionerElement);

						if (!isFullyVisibleResult.isFullyVisible) {
							const tooltipInstance = (
								this.$parent?.$tooltipInstance
								|| this.$dynamicParent?.$tooltipInstance
							);
							let parentFixVisibilityResult = false;

							if (tooltipInstance) {
								parentFixVisibilityResult = await tooltipInstance.fixVisibility(true);
							}

							if (
								!tooltipInstance
								|| !parentFixVisibilityResult
							) {
								this.setNextPosition();
							} else {
								this.isCheckingVisibility = false;
								await this.checkVisibility();
								resolve();
								return;
							}

							if (`${this.tooltipMainPositionSide} ${this.tooltipSecondaryPositionSide}` !== this.initialPosition) {
								this.tooltipPosition.visibility = 'hidden';
								this.isCheckingVisibility = false;
								await this.fixVisibility();
							} else {
								this.isCheckingVisibility = false;
							}
						} else {
							this.isCheckingVisibility = false;
						}

						resolve();
					} catch (error) {
						this.isCheckingVisibility = false;
						reject(error);
					}
				});
			})
				.finally(() => {
					requestAnimationFrame(() => {
						this.tooltipPosition.visibility = '';
					});
				});
		}

		this.isCheckingVisibility = false;

		return Promise.resolve();
	}

	@Public()
	public fixVisibility(setNextPosition?: boolean): Decorated<Promise<boolean>> {
		const tooltipInstance = (
			this.$parent?.$tooltipInstance
			|| this.$dynamicParent?.$tooltipInstance
		);

		if (setNextPosition) {
			this.tooltipPosition.visibility = 'hidden';
			this.setNextPosition();
		}

		if (tooltipInstance) {
			return tooltipInstance
				.fixVisibility(true)
				.then(() => this.calculatePosition())
				.then(() => (
					`${this.tooltipMainPositionSide} ${this.tooltipSecondaryPositionSide}` !== this.initialPosition
				)) as Decorated<Promise<boolean>>;
		}

		return this
			.calculatePosition()
			.then(() => (
				`${this.tooltipMainPositionSide} ${this.tooltipSecondaryPositionSide}` !== this.initialPosition
			)) as Decorated<Promise<boolean>>;
	}

	@Public()
	public onCloseClick(
		event?: ServiceEvent,
		originalEvent?: Event,
	): Decorated<void> {
		if (!event) {
			event = new ServiceEvent({
				type: 'close',
			});
		}

		if (typeof originalEvent !== 'undefined') {
			event.payload = originalEvent;
		}

		if (this.beforeClose) {
			this.beforeClose(event);
		}

		if (!event.defaultPrevented) {
			this.$emit('close');
		}
	}

	protected onTooltipModalClick(event: TouchEvent): void {
		const target = event.target as HTMLElement;

		if (
			!this.$currentInstance.isUnmounted
			&& (
				(
					this.hasCloseButton
					|| this.closeOnModalClick
				)
				&& target !== this.tooltipWrapperElement
				&& !this.tooltipWrapperElement.contains(target)
			)
			&& !event.defaultPrevented
		) {
			if (this.isModal) {
				event.preventDefault();
			}

			const closeEvent = new ServiceEvent({
				type: 'close',
				payload: false,
			});
			this.$emit(
				'close',
				closeEvent,
			);
		}
	}

	protected onTooltipPositionerClick(event: MouseEvent): void {
		event.preventDefault();
	}

	private setNextPosition(): void {
		if (this.tooltipMainPositionSide === 'bottom') {
			this.tooltipMainPositionSide = 'bottom';

			if (this.tooltipSecondaryPositionSide === 'center') {
				this.tooltipSecondaryPositionSide = 'left';
			} else if (this.tooltipSecondaryPositionSide === 'left') {
				this.tooltipSecondaryPositionSide = 'right';
			} else {
				this.tooltipMainPositionSide = 'right';
				this.tooltipSecondaryPositionSide = 'center';
			}
		} else if (this.tooltipMainPositionSide === 'right') {
			this.tooltipMainPositionSide = 'right';

			if (this.tooltipSecondaryPositionSide === 'center') {
				this.tooltipSecondaryPositionSide = 'bottom';
			} else if (this.tooltipSecondaryPositionSide === 'bottom') {
				this.tooltipSecondaryPositionSide = 'top';
			} else {
				this.tooltipMainPositionSide = 'top';
				this.tooltipSecondaryPositionSide = 'center';
			}
		} else if (this.tooltipMainPositionSide === 'top') {
			this.tooltipMainPositionSide = 'top';

			if (this.tooltipSecondaryPositionSide === 'center') {
				this.tooltipSecondaryPositionSide = 'right';
			} else if (this.tooltipSecondaryPositionSide === 'right') {
				this.tooltipSecondaryPositionSide = 'left';
			} else {
				this.tooltipMainPositionSide = 'left';
				this.tooltipSecondaryPositionSide = 'center';
			}
		} else if (this.tooltipMainPositionSide === 'left') {
			this.tooltipMainPositionSide = 'left';

			if (this.tooltipSecondaryPositionSide === 'center') {
				this.tooltipSecondaryPositionSide = 'bottom';
			} else if (this.tooltipSecondaryPositionSide === 'bottom') {
				this.tooltipSecondaryPositionSide = 'top';
			} else {
				this.tooltipMainPositionSide = 'bottom';
				this.tooltipSecondaryPositionSide = 'center';
			}
		}
	}
}

export default toNative(TooltipComponent);
