import merge from 'deepmerge';
import {
	DialogModel,
	DialogOptions,
	DialogGroup,
} from 'interfaces/app';
import parseTag from '../tools/parse-tag';
import { DialogModule } from '../store/index';

export default class Dialog {
	static replaceTags(options: DialogOptions) {
		// In case there is text in the dialog with dynamic content, we can do a replace on the text with replaceTags
		if (options.replaceTags && options.text) {
			options.text = parseTag(
				options.text,
				options.replaceTags,
			);
		}

		return options;
	}

	static openDialog(
		opts: DialogOptions,
		groupName: DialogGroup = 'main',
	) {
		const defaults: DialogOptions = {
			chrome: true,
			type: 'default',
			wrapButtons: false,
		};
		let options: DialogOptions = opts ? merge(
			defaults,
			opts,
		) : defaults;
		options = Dialog.replaceTags(options);

		// Create new dialog model
		DialogModule.open({
			groupName,
			data: options,
		});
		const dialogModel = DialogModule.getByType(groupName);

		// Return the model identifier, so that the dialog can be closed using that id
		return dialogModel;
	}

	static closeDialog(options: DialogModel) {
		if (options.cid) {
			DialogModule.close(options.cid);
		}
	}

	static showLoader(opts?: DialogOptions) {
		const defaults: DialogOptions = {
			modal: true,
			hasclose: false,
			type: 'loader',
			text: window.App.router.$t('dialogTextLoad'),
		};
		const options: DialogOptions = opts ? merge(
			defaults,
			opts,
		) : defaults;

		return Dialog.openDialog(
			options,
			'loader',
		);
	}

	static closeLoader() {
		DialogModule.closeLoader();
	}

	static showAlert(
		opts: DialogOptions,
		groupName?: DialogGroup,
	) {
		const defaults: DialogOptions = {
			modal: true,
			hasclose: true,
			type: 'alert',
		};
		const options: DialogOptions = opts ? merge(
			defaults,
			opts,
		) : defaults;

		if (!options.hasOwnProperty('buttons')) {
			options.buttons = [
				{
					id: 'accept',
					text: window.App.router.$t('dialogButtonOk'),
				},
			];
		}

		return Dialog.openDialog(
			options,
			groupName,
		);
	}

	static showConfirm(opts: DialogOptions) {
		const defaults: DialogOptions = {
			modal: true,
			hasclose: false,
			type: 'confirm',
		};
		const options: DialogOptions = opts ? merge(
			defaults,
			opts,
		) : defaults;

		return Dialog.openDialog(options);
	}

	static showPrompt(opts: DialogOptions) {
		const defaults: DialogOptions = {
			modal: true,
			hasclose: false,
			type: 'prompt',
		};
		const options: DialogOptions = opts ? merge(
			defaults,
			opts,
		) : defaults;

		return Dialog.openDialog(options);
	}

	static showError(opts?: DialogOptions) {
		const defaults: DialogOptions = {
			title: window.App.router.$t('dialogHeaderError'),
			text: window.App.router.$t('dialogTextError'),
			type: 'alert',
		};
		let options: DialogOptions = opts ? merge(
			defaults,
			opts,
		) : defaults;

		if (options.code) {
			options.text = window.App.router.$i18next.exists(`errors.${options.code}`)
				? window.App.router.$t(`errors.${options.code}`)
				: options.text;
			options.title += ` [${options.code}]`;
		}

		options = Dialog.replaceTags(options);

		if (!options.hasOwnProperty('buttons')) {
			options.buttons = [
				{
					id: 'accept',
					text: window.App.router.$t('dialogButtonErrorOk'),
				},
			];
		}

		return Dialog.showAlert(
			options,
			'error',
		);
	}

	static showProgress(opts: DialogOptions) {
		const defaults: DialogOptions = {
			modal: true,
			hasclose: false,
			type: 'progress',
			title: window.App.router.$t('progress'),
			width: 500,
		};
		const options: DialogOptions = opts ? merge(
			defaults,
			opts,
		) : defaults;

		return Dialog.openDialog(options);
	}

	static showSelect(opts: DialogOptions) {
		const defaults: DialogOptions = {
			modal: true,
			hasclose: false,
			type: 'confirm',
			multipleButtons: true,
		};
		const options: DialogOptions = opts ? merge(
			defaults,
			opts,
		) : defaults;

		return Dialog.openDialog(options);
	}

	static hasOpenDialog() {
		return DialogModule.hasOpen;
	}
}
