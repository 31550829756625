import Template from '@root/js/classes/template';
import PageObject from 'classes/pageobject';
import type { GridPhotoData } from 'interfaces/app';
import type * as PI from 'interfaces/project';
import {
	PhotosModule,
	ProductStateModule,
} from 'store';

const zoomObject = (
	objectModel: PI.PageObjectModel,
	zoom: number, // The fraction to scale to, value between 0-1
	startObject: PI.PageObjectModel,
	pageModel: PI.PageModel,
) => {
	const specs = PageObject.specs(
		objectModel,
		pageModel,
	);
	let cropwidth;
	let cropheight;
	let props;

	if (objectModel.templatestateid) {
		const offeringModel = ProductStateModule.getOffering;
		const templatePositions = ProductStateModule.getPageTemplatePositions({
			pageModel,
			dpi: offeringModel?.configdpi,
		});
		const positionModel = templatePositions.find((m) => m.id === objectModel.templatestateid);

		if (positionModel) {
			const photoModel = objectModel.photoid
				? PhotosModule.getById(objectModel.photoid)
				: undefined;

			if (
				photoModel
				&& positionModel.type == 'photo'
			) {
				const photoData: Omit<GridPhotoData, 'url'> = {
					id: photoModel.id,
					width: photoModel.full_width,
					height: photoModel.full_height,
					caption: photoModel.title || undefined,
				};

				if (
					typeof photoModel.fcx !== 'undefined'
					&& photoModel.fcx !== null
					&& typeof photoModel.fcy !== 'undefined'
					&& photoModel.fcy !== null
					&& typeof photoModel.fcw !== 'undefined'
					&& photoModel.fcw !== null
					&& typeof photoModel.fch !== 'undefined'
					&& photoModel.fch !== null
				) {
					photoData.facebox = {
						x: photoModel.fcx,
						y: photoModel.fcy,
						width: photoModel.fcw,
						height: photoModel.fch,
					};
				}

				props = Template.fitPhotoInRectangle(
					{
						x: positionModel.x,
						y: positionModel.y,
						width: positionModel.width,
						height: positionModel.height,
						angle: objectModel.rotate,
						borderwidth: positionModel.borderwidth,
						autoRotate: false,
					},
					photoData,
					undefined,
					{
						resizing: {
							maxScale: offeringModel
								? offeringModel.configdpi / offeringModel.minimumdpi
								: 1000,
							recommendedMaxScale: offeringModel
								? offeringModel.configdpi / offeringModel.qualitydpi
								: 1000,
						},
					},
				);
			}
		}
	}

	if (specs.maxCropWidth - objectModel.cropwidth < specs.maxCropHeight - objectModel.cropheight) {
		const ratio = props ? props.cropWidth / props.cropHeight : startObject.cropwidth / startObject.cropheight;
		cropheight = specs.minCropHeight + (1 - zoom) * (specs.maxCropHeight - specs.minCropHeight);
		cropwidth = Math.min(
			specs.maxCropWidth,
			ratio * cropheight,
		);
	} else {
		const ratio = props ? props.cropHeight / props.cropWidth : startObject.cropheight / startObject.cropwidth;
		cropwidth = specs.minCropWidth + (1 - zoom) * (specs.maxCropWidth - specs.minCropWidth);
		cropheight = Math.min(
			specs.maxCropHeight,
			ratio * cropwidth,
		);
	}

	const cropDiffX = startObject.cropwidth - cropwidth;
	const cropDiffY = startObject.cropheight - cropheight;

	const cropx = Math.max(
		0,
		startObject.cropx + cropDiffX / 2,
	);
	const cropy = Math.max(
		0,
		startObject.cropy + cropDiffY / 2,
	);

	let {
		width,
		height,
		x_axis: xAxis,
		y_axis: yAxis,
	} = startObject;

	// Calculate new object width and x_axis
	let newWidth;
	let newHeight;
	let widthdiff;
	let heightdiff;

	if (specs.maxCropWidth - objectModel.cropwidth < specs.maxCropHeight - objectModel.cropheight) {
		newWidth = cropwidth / cropheight * height;
		widthdiff = width - newWidth;
		width -= widthdiff;

		newHeight = cropheight / cropwidth * width;
		heightdiff = height - newHeight;
		height -= heightdiff;
	} else {
		newHeight = cropheight / cropwidth * width;
		heightdiff = height - newHeight;
		height -= heightdiff;

		newWidth = cropwidth / cropheight * height;
		widthdiff = width - newWidth;
		width -= widthdiff;
	}

	xAxis += widthdiff / 2;
	yAxis += heightdiff / 2;

	ProductStateModule.changePageObject({
		id: objectModel.id,
		cropwidth,
		cropheight,
		cropx: Math.min(
			cropx,
			objectModel.maxwidth - cropwidth,
		),
		cropy: Math.min(
			cropy,
			objectModel.maxheight - cropheight,
		),
		width,
		height,
		x_axis: xAxis,
		y_axis: yAxis,
	});
};

export default zoomObject;
