const URL_PARAMETERS = {
	accessCode: 'access_code',
	country: 'country',
	countryId: 'countryid', // soon to be deprecated (replaced by country)
	currency: 'currency',
	customAttributes: 'customAttributes', // Optional parameters send by partners that need to be returned to webshop
	disableOfferingOptions: 'disableOptions', // Optional parameter send by patners to disable the offering options selection/change
	email: 'email',
	externalOfferingId: 'eoid',
	language: 'language',
	locale: 'locale', // soon to be deprecated (replaced by language)
	sizeSelect: 'sizeselect',
	userToken: 'user_token',
	voucherCode: 'vc',
};

export default URL_PARAMETERS;
