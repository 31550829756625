import './defines';
import TemplatePosition from 'classes/templateposition';
import { TemplateTextPosition } from 'interfaces/app';
import * as PI from 'interfaces/project';
import {
	ProductStateModule,
	ThemeDataModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import { Vue } from 'vue-facing-decorator';
import Template from './template.vue';

interface formData {
	id: string;
	inputLabel: string;
	value: string|null;
}

@Component({
	name: 'ProductAttributesView',
	emits: ['closeDialog'],
	mixins: [Template],
})
class ProductAttributesView extends Vue {
	protected loading = false;

	private get formData() {
		const productModel = ProductStateModule.getProduct;
		const data: formData[] = [];

		if (productModel) {
			const arrThemeVariants = ThemeDataModule.whereVariant({
				themeid: productModel.themeid,
				input: 1,
			});

			arrThemeVariants.forEach((themeVariantModel) => {
				const attributeModel = _.findWhere(
					ProductStateModule.getAttributes,
					{ property: themeVariantModel.tag },
				);
				const value = attributeModel
					? attributeModel.value
					: null;
				data.push({
					id: themeVariantModel.tag,
					inputLabel: this.$t(`productAttributes.${themeVariantModel.label}`),
					value,
				});
			});
		}

		return data;
	}

	protected save() {
		this.loading = true;

		this.formData.forEach((field) => {
			const modelData: RequiredExceptFor<PI.ProductAttributeModel, 'id'> = {
				property: field.id,
				value: field.value,
			};

			const attributeModel = ProductStateModule.addAttribute({
				data: modelData,
			});

			if (attributeModel) {
				ProductStateModule.getEditablePages.forEach((pageModel) => {
					if (pageModel.template) {
						const templatePositions = ProductStateModule.getPageTemplatePositionsAvailable({
							pageModel,
							dpi: ProductStateModule.getOffering?.configdpi,
						});
						const textPositions = templatePositions.filter((m) => m.type === 'text') as TemplateTextPosition[];
						textPositions.forEach((positionModel) => {
							if (
								attributeModel.value
								&& positionModel.productattribute
								&& positionModel.productattribute == attributeModel.property
							) {
								TemplatePosition
									.fillTextPosition(
										pageModel,
										positionModel,
										attributeModel.value,
									)
									.catch(() => {
										// Swallow error: no action required
									});
							}
						});
					}
				});
			}
		});

		this.$emit('closeDialog');
	}
}

export default toNative(ProductAttributesView);
