<template>
	<div
		:key="componentKey"
		class="router-component"
	>
		<DialogView />

		<SupportView
			v-if="showSupport"
		/>

		<div
			v-if="webPushAvailable"
			class="webPushSoftAsk"
		>
			<WebPushSoftAskView	/>
		</div>

		<div id="moduleWrapper">
			<div
				v-if="loading"
				id="framework"
			>
				<div id="appload">
					<svg
						viewBox="0 0 32 32"
						width="64"
						height="64"
					>
						<circle
							id="spinner"
							class="animation-spinner"
							cx="16"
							cy="16"
							r="14"
							fill="none"
						/>
					</svg>
				</div>
			</div>
			<RouterView v-else />
		</div>

		<div id="uploadButton" />
	</div>
</template>

<style lang="less">
.router-component {
	min-height: var(--app-height);
}
</style>
