import * as PI from 'interfaces/project';
import analytics from 'controllers/analytics';
import navigate from 'controllers/navigate';
import template from 'controllers/template';
import * as DialogService from 'services/dialog';
import { OfferingGroups } from 'settings/offerings';
import { AppDataModule, ProductStateModule } from 'store';
import EditorBuildOptionsView from 'views/editor-build-options';
import ProductState from './productstate';

class Product {
	static fill(opts?: {
		showBuildOptions?: boolean;
		navigate?: boolean;
		pageCount?: number;
		replaceContent?: boolean;
	}): Promise<void> {
		const options = {
			showBuildOptions: true,
			navigate: true,
			replaceContent: false,
			pageCount: undefined,
			...opts,
		};

		if (options.replaceContent
			&& !options.showBuildOptions
		) {
			return ProductStateModule.removeProjectContent({
				keepPhotos: true,
			}).then(() => {
				const productid = ProductStateModule.productId;
				if (!productid) {
					throw new Error('Missing required productid');
				}

				return ProductState.select(productid);
			}).then(() => {
				options.replaceContent = false;

				if (ProductStateModule.getProductSettings.autoFillPageDensityId) {
					const offeringModel = ProductStateModule.getOffering;
					if (!offeringModel) {
						throw new Error('Could not find required offeringModel');
					}

					const pageOption = ProductState.getDynamicFillPageOption(
						ProductStateModule.getProductSettings.autoFillPageDensityId,
						ProductStateModule.getPhotosSelected.length,
						offeringModel,
					);

					const isBook = OfferingGroups(
						offeringModel.groupid,
						['BookTypes'],
					);
					const projectPages = isBook
						? ProductStateModule.getPages.length - 2
						: ProductStateModule.getPages.length;

					if (pageOption
						&& pageOption.innerPagesAfterAutoFill > projectPages
						&& ProductStateModule.getPages.length < offeringModel.maxpages
					) {
						const pagesToAdd = pageOption.innerPagesAfterAutoFill - projectPages;
						const promiseAll: Promise<PI.PageModel>[] = [];
						for (
							let y = 0;
							y < pagesToAdd && ProductStateModule.getPages.length < offeringModel.maxpages;
							y += 1
						) {
							promiseAll.push(
								ProductState.addPage({
									autoCompensate: false,
								}),
							);
						}
						return Promise.all(
							promiseAll,
						).then(
							() => Product.fill(options),
						);
					}
				}

				return Product.fill(options);
			}).catch(() => {
				DialogService.openErrorDialog();
				return undefined;
			});
		}

		const productModel = ProductStateModule.getProduct;
		const offeringModel = ProductStateModule.getOffering;

		if (!productModel) {
			throw new Error('Could not find required product model');
		}

		if (!offeringModel) {
			throw new Error('Could not find required offeringModel');
		}

		const groupid = productModel.group;
		const productSettings = ProductStateModule.getProductSettings;
		const orientationOption = ProductState.getDynamicFillOrientation(offeringModel);

		if (
			(
				ProductStateModule.getUnusedPhotos.length > 0
				|| !options.showBuildOptions
			)
			&& (
				typeof productSettings.autoFill === 'undefined'
				|| productSettings.autoFill
			)
		) {
			analytics.trackEvent(
				'Photos selected',
				{
					category: 'Process',
					label: 'Product photo count',
					value: ProductStateModule.getPhotosSelected.length,
				},
			);

			if (
				options.showBuildOptions
				&& OfferingGroups(
					groupid,
					['BuildOptions'],
				)
			) {
				return new Promise((resolve) => {
					// Add childView to dialog
					const { close: closeDialog } = DialogService.openDialog({
						header: {
							hasCloseButton: false,
						},
						body: {
							component: EditorBuildOptionsView,
							props: {
								orientation: orientationOption,
								productLabel: AppDataModule.productGroupName(groupid),
								value: true,
							},
							listeners: {
								'update:value': (value: boolean) => {
									analytics.trackEvent(
										'Save build configuration',
										{
											category: 'Button',
											label: (
												value
													? 'Auto'
													: 'Manual'
											),
										},
									);
									ProductState.saveBuildConfiguration({
										autoFill: value,
									});
									closeDialog();
								},
								close: () => {
									closeDialog();
								},
							},
							styles: {
								padding: '0',
								backgroundColor: 'transparent',
							},
						},
						listeners: {
							close: () => {
								resolve();
							},
						},
						styles: {
							padding: '0',
							backgroundColor: 'transparent',
						},
						theme: 'light',
						width: 1100,
					});
				});
			}

			// fill template with photos not yet included
			return template
				.autoFill(options.pageCount)
				.catch((e) => {
					if (typeof window.glBugsnagClient !== 'undefined') {
						const err = new Error(`error in auto filling: ${e}`);
						window.glBugsnagClient.notify(
							err,
							(event) => { event.severity = 'error'; },
						);
					} else {
						console.log(e);
					}
				})
				.finally(() => {
					ProductState.save();

					if (
						options.navigate
						&& ProductStateModule.productId
					) {
						// Move forward in process
						navigate.goForward('fill');
					}
				});
		}

		if (
			options.navigate
			&& ProductStateModule.productId
		) {
			navigate.goForward('fill');
		}

		return Promise.resolve();
	}
}

export default Product;
