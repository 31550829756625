import TooltipComponent from 'components/tooltip';
import {
	closeAuthDialog,
	closeLoaderDialog,
	openAlertDialog,
	openAuthDialog,
	openConfirmDialog,
	openDialog,
	openDialogNew,
	openErrorDialog,
	openLoaderDialog,
	openProgressDialog,
	openPromptDialog,
} from 'services/dialog';
import { openToolbar } from 'services/toolbar';
import { openTooltip } from 'services/tooltip';
import { string as stringTools } from 'tools';
import {
	type ComponentObjectPropsOptions,
	type ComponentPublicInstance,
	createApp,
	createElementVNode,
	h,
	reactive,
	Text,
	type VNode,
	type VNodeArrayChildren,
} from 'vue';
import type { VueConsExtended } from 'vue-facing-decorator';
import type { Cons } from 'vue-facing-decorator/dist/component';

export const recomputeTriggers = reactive(new Map<string, string>());

function findChild(
	vNode: VNode,
	callback: (vNode: VNodeArrayChildren[number]) => boolean,
): VNode | undefined {
	if (Array.isArray(vNode.children)) {
		const childFound = vNode.children.find(callback);

		if (childFound) {
			return childFound as VNode;
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const child of vNode.children) {
			if (
				child
				&& typeof child === 'object'
				&& 'children' in child
			) {
				const found = findChild(
					child,
					callback,
				);

				if (found) {
					return found;
				}
			}
		}
	}

	return undefined;
}

function processProps<Component extends VueConsExtended>(
	component: Component,
	props?: PublicNonFunctionProps<NonVuePublicOptionalProps<InstanceType<Component>>>,
	listeners?: Record<string, Array<(...args: any[]) => any> | ((...args: any[]) => any)>,
	parent?: ComponentPublicInstance,
): Record<string, any> | undefined {
	let finalProps: Record<string, any> | undefined;

	if (props) {
		const propsKeys = Object.keys(props) as Array<keyof typeof props>;
		finalProps = propsKeys.reduce(
			(realProps, propKey) => {
				if (
					component.props
					&& propKey in component.props
				) {
					realProps[propKey] = props[propKey];
				} else if (parent?.$currentInstance.appContext.mixins.length) {
					const { mixins } = parent.$currentInstance.appContext;
					const propFoundInMixin = mixins.find((mixin) => (
						mixin.props
						&& propKey in mixin.props
					));

					if (propFoundInMixin) {
						realProps[propKey] = props[propKey];
					}
				}

				return realProps;
			},
			{} as Record<string, any>,
		);
	}

	if (listeners) {
		if (!finalProps) {
			finalProps = {};
		}

		const listenerKeys = Object.keys(listeners);

		// eslint-disable-next-line no-restricted-syntax
		for (const listenerKey of listenerKeys) {
			const listener = listeners[listenerKey];
			const [first, ...rest] = listenerKey;
			const normalizedListenerKey = `on${[first.toUpperCase(), ...rest].join('')}`;

			if (Array.isArray(listener)) {
				finalProps[normalizedListenerKey] = listener;
			} else {
				finalProps[normalizedListenerKey] = [listener];
			}
		}
	}

	return finalProps;
}

function processSlots(
	slots?: RenderComponentOptions<Cons>['slots'],
	parent?: ComponentPublicInstance,
): Record<string, any> | undefined {
	let finalSlots: Record<string, any> | undefined;

	if (slots) {
		finalSlots = {};
		const slotsKeys = Object.keys(slots);

		// eslint-disable-next-line no-restricted-syntax
		for (const slotKey of slotsKeys) {
			let slot = slots[slotKey];

			if (!Array.isArray(slot)) {
				slot = [slot];
			}

			if (slot.length) {
				finalSlots[slotKey] = [];
			}

			// eslint-disable-next-line no-restricted-syntax
			for (const individualSlot of slot) {
				if (typeof individualSlot === 'object') {
					const slotProps = processProps(
						individualSlot.component as VueConsExtended,
						individualSlot.props,
						individualSlot.listeners,
						parent,
					);
					finalSlots[slotKey].push(h(
						individualSlot.component as VueConsExtended,
						slotProps,
					));
				} else {
					const vNode = createElementVNode(Text);
					finalSlots[slotKey].push(h(
						vNode,
						{},
						individualSlot,
					));
				}
			}
		}
	}

	return finalSlots;
}

export function configureChildren(appInstance: ReturnType<typeof createApp>): void {
	appInstance.use({
		install(app) {
			app.mixin({
				computed: {
					// eslint-disable-next-line vue/no-reserved-keys
					$children(this: ComponentPublicInstance): ComponentPublicInstance<any>[] {
						let children: VNode[] = [];

						if (Array.isArray(this.$.subTree.children)) {
							children = this.$.subTree.children as VNode[];
							const rootElement = this.$el as HTMLElement;

							while (
								children.length === 1
								&& children[0]
								&& typeof children[0] === 'object'
								&& Array.isArray(children[0].children)
								&& (
									(
										'shapeFlag' in children[0]
										&& children[0].shapeFlag === 16
									)
									|| (
										children[0].el instanceof Element
										&& children[0].el.isSameNode(rootElement)
									)
								)
							) {
								children = children[0].children as VNode[];
							}
						}

						return children
							.map((child) => (
								'component' in child
								&& child.component
								&& child.component.proxy
							))
							.filter(Boolean);
					},
				},
				mounted() {
					this.$forceCompute('$children');
				},
			});
		},
	});
}

export function configureCurrentInstance(appInstance: ReturnType<typeof createApp>): void {
	appInstance.use({
		install(app) {
			app.mixin({
				computed: {
					$currentInstance(this: ComponentPublicInstance) {
						return this.$;
					},
				},
			});
		},
	});
}

export function configureDevTools(appInstance: ReturnType<typeof createApp>): void {
	if (process.env.NODE_ENV !== 'production') {
		appInstance.config.performance = true;
	}
}

export function configureDialogService(appInstance: ReturnType<typeof createApp>): void {
	if (
		typeof appInstance.config.globalProperties.$openDialog !== 'undefined'
		&& typeof appInstance.config.globalProperties.$openDialogNew !== 'undefined'
		&& typeof appInstance.config.globalProperties.$openLoaderDialog !== 'undefined'
		&& typeof appInstance.config.globalProperties.$closeLoaderDialog !== 'undefined'
	) {
		return;
	}

	if (!appInstance.config.globalProperties.$closeAuthDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$closeAuthDialog = closeAuthDialog;
			},
		});
	}
	if (!appInstance.config.globalProperties.$closeLoaderDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$closeLoaderDialog = closeLoaderDialog;
			},
		});
	}
	if (!appInstance.config.globalProperties.$openAlertDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openAlertDialog = function $openAlertDialog(
					this: ComponentPublicInstance,
					options,
				) {
					return openAlertDialog({
						...(options || {}),
						parent: this,
					});
				};
			},
		});
	}
	if (!appInstance.config.globalProperties.$openAuthDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openAuthDialog = function $openAuthDialog(
					this: ComponentPublicInstance,
					options,
				) {
					return openAuthDialog({
						...(options || {}),
						parent: this,
					});
				};
			},
		});
	}
	if (!appInstance.config.globalProperties.$openConfirmDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openConfirmDialog = function $openConfirmDialog(
					this: ComponentPublicInstance,
					options,
				) {
					return openConfirmDialog({
						...(options || {}),
						parent: this,
					});
				};
			},
		});
	}
	if (!appInstance.config.globalProperties.$openDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openDialog = function $openDialog(
					this: ComponentPublicInstance,
					options,
				) {
					return openDialog({
						...(options || {}),
						parent: this,
					});
				};
			},
		});
	}
	if (!appInstance.config.globalProperties.$openDialogNew) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openDialogNew = function $openDialogNew(
					this: ComponentPublicInstance,
					options,
				) {
					return openDialogNew({
						...(options || {}),
						parent: this,
					});
				};
			},
		});
	}
	if (!appInstance.config.globalProperties.$openErrorDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openErrorDialog = function $openErrorDialog(
					this: ComponentPublicInstance,
					options,
				) {
					return openErrorDialog({
						...(options || {}),
						parent: window.App.router,
					});
				};
			},
		});
	}
	if (!appInstance.config.globalProperties.$openLoaderDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openLoaderDialog = function $openLoaderDialog(
					this: ComponentPublicInstance,
					options,
				) {
					return openLoaderDialog({
						...(options || {}),
						parent: window.App.router,
					});
				};
			},
		});
	}
	if (!appInstance.config.globalProperties.$openProgressDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openProgressDialog = function $openProgressDialog(
					this: ComponentPublicInstance,
					options,
				) {
					return openProgressDialog({
						...(options || {}),
						parent: this,
					});
				};
			},
		});
	}
	if (!appInstance.config.globalProperties.$openPromptDialog) {
		appInstance.use({
			install(app) {
				app.config.globalProperties.$openPromptDialog = function $openPromptDialog(
					this: ComponentPublicInstance,
					options,
				) {
					return openPromptDialog({
						...(options || {}),
						parent: this,
					});
				};
			},
		});
	}
}

export function configureDynamicParent(appInstance: ReturnType<typeof createApp>): void {
	appInstance.use({
		install(app) {
			app.mixin({
				inject: {
					$dynamicParent: {
						from: '$dynamicParent',
						default: undefined,
					},
				},
			});
		},
	});
}

/**
 * Configure/create _isVueClass flag to determine if the class is Vue class or not.
 * @param appInstance The createApp instance
 */
export function configureFlagPrototype(appInstance: ReturnType<typeof createApp>): void {
	if (typeof appInstance.config.globalProperties._isVueClass !== 'undefined') {
		return;
	}

	appInstance.use({
		install(app) {
			Object.defineProperty(
				app.config.globalProperties,
				'_isVueClass',
				{
					get() {
						return true;
					},
				},
			);
		},
	});
}

export function configureForceCompute(appInstance: ReturnType<typeof createApp>): void {
	if (typeof appInstance.config.globalProperties.$forceCompute !== 'undefined') {
		return;
	}

	appInstance.use({
		install(app) {
			app.config.globalProperties.$forceCompute = function $forceCompute(
				this: ComponentPublicInstance,
				computedName: string,
				forceUpdate = true,
			) {
				recomputeTriggers.set(
					computedName,
					stringTools.randomUUID(),
				);

				if (forceUpdate) {
					this.$forceUpdate();
				}
			};
		},
	});
}

export function configureGetSlot(appInstance: ReturnType<typeof createApp>): void {
	if (typeof appInstance.config.globalProperties.$getSlot !== 'undefined') {
		return;
	}

	appInstance.use({
		install(app) {
			app.config.globalProperties.$getSlot = function $getSlot(
				this: ComponentPublicInstance,
				name: string,
			) {
				if (
					this.$slots[name]
					&& Array.isArray(this.$.subTree.children)
				) {
					const slotChildFound = findChild(
						this.$.subTree,
						(child) => !!(
							typeof child === 'object'
							&& child
							&& 'shapeFlag' in child
							&& child.shapeFlag === 16 /* Slots have a shape flag of 16 */
							&& child.key === `_${name}`
						),
					);

					if (
						slotChildFound
						&& Array.isArray(slotChildFound.children)
						&& slotChildFound.children.length
						&& slotChildFound.children[0]
						&& typeof slotChildFound.children[0] === 'object'
						&& 'component' in slotChildFound.children[0]
					) {
						return slotChildFound.children[0].component?.proxy as ComponentPublicInstance<any> | null | undefined;
					}
				}

				return undefined;
			};
		},
	});
}

export function configureIsMounted(appInstance: ReturnType<typeof createApp>): void {
	appInstance.use({
		install(app) {
			app.mixin({
				data() {
					return {
						isMounted: false,
					};
				},
				beforeUnmount(this: ComponentPublicInstance): void {
					this.isMounted = false;
				},
				mounted(this: ComponentPublicInstance): void {
					this.$nextTick(() => {
						this.isMounted = true;
					});
				},
			});
		},
	});
}

export function configureRenderComponent(appInstance: ReturnType<typeof createApp>): void {
	if (typeof appInstance.config.globalProperties.$renderComponent !== 'undefined') {
		return;
	}

	appInstance.use({
		install(app) {
			app.config.globalProperties.$renderComponent = function $renderComponent(
				this: ComponentPublicInstance,
				options,
			) {
				const {
					component,
					element,
					listeners,
					props,
					slots,
				} = options;
				const container = document.createElement('div');
				const finalProps = processProps(
					component as unknown as VueConsExtended,
					props,
					listeners,
					this,
				);
				const finalSlots = processSlots(
					slots,
					this,
				);

				const vNode = h(
					component,
					finalProps,
					finalSlots,
				);
				let appProps: Record<string, any> | undefined;

				if (
					typeof finalProps !== 'undefined'
					&& 'theme' in finalProps
				) {
					appProps = {
						theme: finalProps.theme,
					};
				} else if (this.internalTheme !== 'auto') {
					appProps = {
						theme: this.internalTheme,
					};
				}

				const childApp = createApp(
					{
						provide: {
							$dynamicParent: this,
						},
						render() {
							return vNode;
						},
					},
					appProps,
				);
				const originalChildAppFromAppContext = childApp._context.app;
				Object.assign(
					childApp._context,
					this.$currentInstance.appContext,
				);
				childApp._context.app = originalChildAppFromAppContext;
				childApp.mount(container);
				const instance = vNode.component?.proxy as ComponentPublicInstance;
				const elementToAppendChild = (
					element
					|| document.getElementById('webapp')
				) as HTMLDivElement;
				const childAppElement = instance.$el as Element;

				if (options.insertBefore) {
					elementToAppendChild.insertBefore(
						childAppElement,
						options.insertBefore,
					);
				} else {
					elementToAppendChild.appendChild(childAppElement);
				}

				this.onUnmount(() => {
					childApp.unmount();

					if (elementToAppendChild.contains(childAppElement)) {
						elementToAppendChild.removeChild(childAppElement);
					}
				});

				return instance as ComponentPublicInstance<any>;
			};
		},
	});
}

export function configureTheme(appInstance: ReturnType<typeof createApp>): void {
	appInstance.use({
		install(app) {
			app.mixin({
				props: {
					theme: {
						acceptedValues: [
							'auto',
							'dark',
							'light',
						],
						default: 'auto',
						description: 'Defines the theme of the component',
						event: 'theme-change',
						type: String,
					},
				},
				emits: ['theme-change'],
				data() {
					return {
						internalTheme: 'auto',
						parentThemeWatch: undefined,
					};
				},
				beforeUnmount(this: ComponentPublicInstance): void {
					this.parentThemeUnwatch?.();
				},
				mounted(this: ComponentPublicInstance): void {
					const parent = (
						this.$parent
						|| this.$dynamicParent
					);

					if (this.checkIfComponentIsRouterView()) {
						return;
					}

					if (this.theme === 'auto') {
						if (
							!parent
							|| parent.internalTheme === 'auto'
						) {
							this.internalTheme = (
								window.matchMedia('(prefers-color-scheme: dark)').matches
									? 'dark'
									: 'light'
							);
						}

						if (parent) {
							this.parentThemeUnwatch = parent.$watch(
								'internalTheme',
								() => {
									if (
										parent
										&& parent.internalTheme !== 'auto'
									) {
										this.internalTheme = parent.internalTheme;
									}
								},
								{
									immediate: true,
								},
							);
						}
					}
				},
				updated(this: ComponentPublicInstance): void {
					this.setTheme();
				},
				methods: {
					checkIfComponentIsRouterView(): boolean {
						/**
						 * The RouterView component uses the same DOM element as the child component
						 * of the route, meaning that setting the theme on this component would
						 * override the theme of the child component.
						 */
						if (this.$.type.name === 'RouterView') {
							return true;
						}

						return false;
					},
					setTheme(): void {
						if (this.checkIfComponentIsRouterView()) {
							return;
						}

						if (
							this.$el
							&& 'classList' in this.$el
							&& this.internalTheme !== 'auto'
						) {
							this.$el.classList.remove('dark');
							this.$el.classList.remove('light');

							if (this.internalTheme === 'light') {
								this.$el.classList.add('light');
							} else if (this.internalTheme === 'dark') {
								this.$el.classList.add('dark');
							}
						}
					},
					toggleTheme(): void {
						this.internalTheme = (
							this.internalTheme === 'dark'
								? 'light'
								: 'dark'
						);
					},
				},
				// eslint-disable-next-line vue/order-in-components
				watch: {
					internalTheme: {
						handler: function onInternalThemeChange(this: ComponentPublicInstance): void {
							if (this.checkIfComponentIsRouterView()) {
								return;
							}

							if (this.internalTheme !== this.theme) {
								this.$emit(
									'theme-change',
									this.internalTheme,
								);
							}

							const executionTime = Date.now();
							const changeThemeInterval = setInterval(
								() => {
									if (
										this.$el
										&& 'classList' in this.$el
									) {
										this.setTheme();
										clearInterval(changeThemeInterval);
									} else if ((Date.now() - executionTime) > (10 * 1000)) {
										clearInterval(changeThemeInterval);
									}
								},
							);
						},
					},
					theme: {
						immediate: true,
						handler: function onThemeChange(
							this: ComponentPublicInstance,
							newVaValue: 'auto' | 'dark' | 'light',
							oldValue?: 'auto' | 'dark' | 'light',
						): void {
							if (this.checkIfComponentIsRouterView()) {
								return;
							}

							this.internalTheme = this.theme;

							if (
								oldValue
								&& oldValue !== 'auto'
							) {
								this.$nextTick(() => {
									this.parentThemeUnwatch?.();
								});
							}
						},
					},
				},
			});
		},
	});
}

export function configureToolbarService(appInstance: ReturnType<typeof createApp>): void {
	if (typeof appInstance.config.globalProperties.$openToolbar !== 'undefined') {
		return;
	}

	appInstance.use({
		install(app) {
			app.config.globalProperties.$openToolbar = function $openToolbar(
				this: ComponentPublicInstance,
				options,
			) {
				return openToolbar({
					...options,
					parent: this,
				});
			};
		},
	});
}

export function configureTooptipInstance(appInstance: ReturnType<typeof createApp>): void {
	if (typeof appInstance.config.globalProperties.$tooltipInstance !== 'undefined') {
		return;
	}

	appInstance.use({
		install(app) {
			app.config.globalProperties.$tooltipInstance = undefined;
			app.mixin({
				beforeCreate(this: ComponentPublicInstance) {
					let parent = (
						this.$parent
						|| this.$dynamicParent
					);
					let tooltipComponent = TooltipComponent;

					if ('default' in tooltipComponent) {
						tooltipComponent = tooltipComponent.default as typeof TooltipComponent;
					}

					if (
						!parent
						&& 'provide' in this.$.type
						&& typeof this.$.type.provide !== 'undefined'
					) {
						if (typeof this.$.type.provide === 'function') {
							parent = this.$.type.provide().$dynamicParent;
						} else {
							parent = this.$.type.provide.$dynamicParent as any;
						}
					}

					if (
						parent
						&& parent.$currentInstance?.type.__vfdConstructor === tooltipComponent.__vfdConstructor
					) {
						this.$tooltipInstance = parent as InstanceType<typeof TooltipComponent<Cons>>;
					}
				},
			});
		},
	});
}

export function configureTooltipService(appInstance: ReturnType<typeof createApp>): void {
	if (typeof appInstance.config.globalProperties.$openTooltip !== 'undefined') {
		return;
	}

	appInstance.use({
		install(app) {
			app.config.globalProperties.$openTooltip = function $openTooltip(
				this: ComponentPublicInstance,
				options,
			) {
				return openTooltip({
					...options,
					parent: this,
				});
			};
		},
	});
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	configureTooptipInstance(appInstance);
}

export function defineRenderComponentOptions<Component extends Cons>(
	options: RenderComponentOptions<Component>,
): RenderComponentOptions<Component> {
	return options;
}

/**
 * Configure/create .onUnmount() hook for Vue components
 * to be able to listen on Vue instance when it's going to be destroyed.
 * @param appInstance The createApp instance
 */
export function configureUnmount(appInstance: ReturnType<typeof createApp>): void {
	if (typeof appInstance.config.globalProperties.onUnmount !== 'undefined') {
		return;
	}

	const unmountSymbol = Symbol('unmount-symbol');
	appInstance.use({
		install(app) {
			app.mixin({
				data() {
					return {
						[unmountSymbol]: [],
					};
				},
				beforeUnmount(this: ComponentPublicInstance & { [unmountSymbol]: Array<((...args: any[]) => any)> }) {
					// eslint-disable-next-line no-restricted-syntax
					for (const fn of this[unmountSymbol]) {
						fn();
					}
				},
			});
			app.config.globalProperties.onUnmount = function onUnmount(
				this: ComponentPublicInstance & { [unmountSymbol]: Array<((...args: any[]) => any)> },
				fn,
			) {
				if (this.$.isUnmounted) {
					fn();
					return;
				}

				this[unmountSymbol].push(fn);
			};
		},
	});
}

export function getComponentProps<Component extends Cons>(
	component: Component,
	parent?: ComponentPublicInstance,
): ComponentObjectPropsOptions<Component> | undefined {
	const typedComponent = component as unknown as VueConsExtended<Component>;
	let props: ComponentObjectPropsOptions<Component> | undefined;

	if (typedComponent.props) {
		props = typedComponent.props as ComponentObjectPropsOptions<Component>;
	}

	if (parent?.$currentInstance.appContext.mixins.length) {
		const { mixins } = parent.$currentInstance.appContext;

		// eslint-disable-next-line no-restricted-syntax
		for (const mixin of mixins) {
			if (mixin.props) {
				props = {
					...mixin.props,
					...props,
				};
			}
		}
	}

	return props;
}

/* eslint-enable @typescript-eslint/indent */
export function getInstanceAPI<Component extends InstanceType<Cons>>(instance: Component): NonVuePublicProps<Component> {
	const instanceProps = Object.keys(instance.$options.props || {}) as (keyof NonVuePublicProps<PublicNonFunctionProps<Component>>)[];
	const instanceMethods = Object.keys(instance.$options.methods || {}) as (keyof NonVuePublicProps<PublicFunctionProps<Component>>)[];

	return ([] as (keyof NonVuePublicProps<Component>)[])
		.concat(instanceProps)
		.concat(instanceMethods)
		.reduce(
			(api, key) => {
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				const keyType = getTypeOfMember(
					instance,
					key,
				);

				if (keyType === 'prop') {
					Object.defineProperty(
						api,
						key,
						{
							enumerable: true,
							get() {
								return instance[key];
							},
							set(value) {
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								setComponentProp(
									instance,
									key,
									value,
								);
							},
						},
					);
				} else if (
					keyType === 'method'
					&& instance.$options.methods?.[key as any].public
				) {
					const instanceMethod = instance[key] as (...args: any[]) => any;
					Object.defineProperty(
						api,
						key,
						{
							enumerable: true,
							value(...args: any[]) {
								return instanceMethod.apply(
									instance,
									args,
								);
							},
						},
					);
				}

				return api;
			},
			{} as NonVuePublicProps<Component>,
		);
}

export function getTypeOfMember<Component extends ComponentPublicInstance>(
	component: Component,
	key: keyof Component,
): 'method' | 'prop' | false {
	if (
		component.$options.props
		&& key in component.$options.props
	) {
		return 'prop';
	}
	if (
		component.$options.methods
		&& key in component.$options.methods
	) {
		return 'method';
	}

	return false;
}

/**
 * Set a component prop without triggering Vue warnings.
 * This is intended for when the component instance where created
 * programatically and the parent component is the one actually
 * setting the prop and not the component itself.
 * @param component The Vue component instance to set the prop to.
 * @param prop The prop to set.
 * @param value The value to set the prop to.
 */
export function setComponentProp<
	Component extends ComponentPublicInstance,
	Prop extends keyof Component,
	Value extends Component[Prop],
>(
	component: Component,
	prop: Prop,
	value: Value,
): void {
	component.$.props[prop as any] = value;
}
